import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { FormsModule } from '@angular/forms';

import { AdmiraltyRoutingModule } from './admiralty-routing.module';
import { CommonJoinedModule } from '../common-joined/common-joined.module';
import { QuickHtmlModule } from '../quick-html/quick-html.module';
import { SharedModule } from '../shared/shared.module';

import { FleetAdministrationComponent } from './fleet-administration/fleet-administration.component';
import { FleetEditComponent } from './fleet-edit/fleet-edit.component';
import { FleetListComponent } from './fleet-list/fleet-list.component';
import { FleetOrderEditComponent } from './fleet-order-edit/fleet-order-edit.component';
import { FleetOrdersComponent } from './fleet-orders/fleet-orders.component';
import { OutfitEditComponent } from './outfit-edit/outfit-edit.component';
import { SharedShipClassesComponent } from './shared-ship-classes/shared-ship-classes.component';
import { ShipClassesComponent } from './ship-classes/ship-classes.component';
import { ShipClassEditComponent } from './ship-class-edit/ship-class-edit.component';
import { ShipClassTitleAndLegendComponent } from './ship-class-title-and-legend/ship-class-title-and-legend.component';
import { ShipEditComponent } from './ship-edit/ship-edit.component';
import { ShipListComponent } from './ship-list/ship-list.component';

@NgModule({
  declarations: [
    FleetAdministrationComponent,
    FleetEditComponent,
    FleetListComponent,
    FleetOrderEditComponent,
    FleetOrdersComponent,
    OutfitEditComponent,
    SharedShipClassesComponent,
    ShipClassesComponent,
    ShipClassEditComponent,
    ShipClassTitleAndLegendComponent,
    ShipEditComponent,
    ShipListComponent,
  ],
  imports: [
    CommonModule,
    CdkAccordionModule,
    CommonJoinedModule,
    FormsModule,
    QuickHtmlModule,
    SharedModule,
    AdmiraltyRoutingModule,
  ],
  exports: [
    FleetAdministrationComponent,
    FleetListComponent,
    FleetOrdersComponent,
    SharedShipClassesComponent,
    ShipClassesComponent,
    ShipListComponent,
  ]
})
export class AdmiraltyModule { }
