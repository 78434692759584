<div id="_SHIPCLASS-CENTER_" class="w-full flex flex-col text-1.25 iBTTiny:w-11/12 md:w-full iBTTiny:mx-auto md:m-0">
  <div class="ism-gold text-1.75">Ship Class List</div>
  <div id="Create-New-Class" class="w-full z-10 sticky text-white flex flex-col">
    <ship-class-title-and-legend [legendDisplay]="'Create New Ship Class'">
      <ism-svg data-tooltip="Create New Class" class="p-2 tooltip-tr" [name]="'plus'" (click)="newShipClass()"></ism-svg>
    </ship-class-title-and-legend>
  </div>
  <!--  -->
  @for (shipClass of shipClasses() | orderBy: "className"; track shipClass) {
    <div class="my-2 w-full text-white border bg-black flex flex-col bg-opacity-30">
      <!--  -->
      <div id="_SHIPCLASS-LINE-1_" class="mx-2 flex items-center justify-between">
        <div id="_class-name_" class="px-2 w-1/4 flex items-center hover:text-yellow-300 hover:underline visited:text-white">
          <div class="mr-2 lanPixel2xl:whitespace-nowrap tooltip-tr" [attr.data-tooltip]="'Ship Class Name (Click to edit)'" (click)="editShipClass(shipClass)">
            {{ shipClass.className }}
          </div>

          <div id="_ICON-SET_" class="m-1 flex items-center">
            <!-- Edit Class Icon -->
            <!-- <a [attr.data-tooltip]="'Edit Class'" class="mr-1 flex items-center tooltip-tr" (click)="editShipClass(shipClass)">
              <ism-svg [name]="'pencilSquare'" [classString]="'w-8 h-8 flex items-center ism-gold'"></ism-svg>
            </a> -->
            <!-- Delete Class Icon -->
            @if (shipClass.inactive) {
              <a [attr.data-tooltip]="'Delete Class'" class="ml-1 flex items-center tooltip-tr" type="button" (click)="deleteShipClass(shipClass)">
                <ism-svg [name]="'trashcan'" [classString]="'w-8 h-8 flex items-center ism-gold'"></ism-svg>
              </a>
            }
          </div>
        </div>

        <div id="_hull-class_" class="w-1/4 text-center flex justify-center lanPixel2xl:whitespace-nowrap">
          <div class="tooltip-tr" data-tooltip="Hull Class">
            {{ shipClass.hullClass }}
          </div>
        </div>

        <div id="_ac-am-xo-xog_" class="w-1/4 text-center flex flex-1 items-center justify-center">
          @if (shipClass.atmosphericCapability) {
            <div class="px-2 text-center tooltip-tr" data-tooltip="Atmospheric Capabilities">AC</div>
          }
          @if (shipClass.advancedManeuvering > 0) {
            <div class="px-2 text-center">
              <div class="tooltip-t" data-tooltip="Adv.Manuvering">AM({{ shipClass.advancedManeuvering }})</div>
            </div>
          }
          @if (shipClass.xo > 0) {
            <div class="px-2 text-center tooltip-tr" data-tooltip="External Ordinace">{{ shipClass.xo }} XO</div>
          }
          @if (shipClass.xog > 0) {
            <div class="px-2 text-center tooltip-tr" data-tooltip="Ext-Ord / Gun Boats">{{ shipClass.xog }} XOg</div>
          }
        </div>

        <div class="w-1/4 text-right flex justify-end lanPixel2xl:whitespace-nowrap">
          <div class="tooltip-tl" data-tooltip="Hull Spaces">{{ shipClass.actualSize }} HS</div>
        </div>
      </div>

      <div id="_SHIPCLASS-LINE-2_" class="mx-2 flex items-center justify-between">
        <div id="_turn-mode_" class="px-2 lanPixel2xl:whitespace-nowrap">
          <div data-tooltip="Turn Mode" class="tooltip-tr">[{{ shipClass.turnMode }}]</div>
        </div>
        <div data-tooltip="SSD - Ship System Design" class="px-2 w-full text-center break-all lanPixel2xl:whitespace-normal tooltip-tr">{{ shipClass.ssd }}</div>
        <div data-tooltip="SPEED: Max / Cruise" class="px-2 text-right lanPixel2xl:whitespace-nowrap tooltip-tl">[{{ shipClass.speedMaxStrategic || 0 }} / {{ shipClass.speedCruiseStrategic || 0 }}]</div>
      </div>

      <div id="_SHIPCLASS-LINE-3_" class="mx-2 flex items-center justify-between">
        <div id="_OUTFIT_" class="px-2 flex items-center lanPixel2xl:whitespace-nowrap tooltip-tr" data-tooltip="OUTFIT: Amunitions, Mounts, & Small Craft (Click to edit)" (click)="editShipClassOutfit(shipClass)">
          @if (shipClass.mgCount + shipClass.boatBayPoints + shipClass.xo > 0) {
            @if (shipClass.outfit.length > 2) {
              <div class="hover:text-yellow-300 hover:underline visited:text-white">
                {{ shipClass.outfit | outfitKeyValue }}
              </div>
            }
            @if (!shipClass.outfit || shipClass.outfit.length == 2) {
              <div class="flex-1">Incomplete Outfit</div>
            }

            <!-- <a id="_EDIT-svgPencil_" type="button" data-tooltip="Edit Outfit" class="mx-2 flex items-center tooltip-tr" (click)="editShipClassOutfit(shipClass)">
              <ism-svg [name]="'pencilSquare'" [classString]="'w-8 h-8 flex items-center text-yellow-400'"></ism-svg>
            </a> -->
          }
        </div>
        <!-- _FIGHTER-OUTFIT_ -->
        @if (shipClass.vCount > 0) {
          <div id="_FIGHTER-OUTFIT_" class="px-2 text-right flex items-center justify-end lanPixel2xl:whitespace-nowrap tooltip-tl" data-tooltip="Fighter Outfit (Click to edit)" (click)="editShipClassFighterOutfit(shipClass)">
            @if (shipClass.fighterOutfit.length > 2) {
              <div class="text-right hover:text-yellow-300 hover:underline visited:text-white">{{ shipClass.fighterOutfit | outfitKeyValue }}</div>
            }
            @if (!shipClass.fighterOutfit || shipClass.outfit.length == 2) {
              <div class="px-1 text-right">Incomplete Fighter Outfit</div>
            }
            <!-- <a id="_EDIT-svgPencil_" data-tooltip="Edit Fighter Outfit" type="button" class="mx-2 flex items-center tooltip-tl" (click)="editShipClassFighterOutfit(shipClass)">
              <ism-svg [name]="'pencilSquare'" [classString]="'w-8 h-8 flex items-center ism-gold'"></ism-svg>
            </a> -->
          </div>
        }
      </div>

      <div id="_SHIPCLASS-LINE-4_" class="mx-2 flex items-center justify-between">
        <div id="_build-costs_" class="flex-1 flex items-center justify-stretch">
          <div class="px-2 flex-1 tooltip-tr" data-tooltip="Build Costs">Build: {{ shipClass.buildCost }} MC</div>
          <div class="px-2 flex-1 tooltip-tr" data-tooltip="Maintenance Fund">Maint: {{ shipClass.maintenanceCost }} MC</div>
        </div>
        <div id="_funds-craft-costs_" class="ml-2 flex-1 flex items-center justify-between">
          @if (shipClass.mgCount > 0) {
            <div class="text-center lanPixel2xl:whitespace-nowrap tooltip-tl" data-tooltip="Missile Fund">M: {{ shipClass.missileFund }} MC</div>
          }
          @if (shipClass.vCount > 0) {
            <div class="pl-4 text-center lanPixel2xl:whitespace-nowrap tooltip-tr" data-tooltip="Fighter Fund">F: {{ shipClass.fighterFund }} MC</div>
          }
          @if (shipClass.boatBayPoints + shipClass.vCount > 0) {
            <div class="pl-4 text-right :whitespace-nowrap tooltip-tl" data-tooltip="Small Craft Cost">SC: {{ shipClass.smallCraftCost }} MC</div>
          }
        </div>
      </div>
    </div>
  }
</div>
