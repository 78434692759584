<div id="_FLEET-ADMIN-CENTER_" class="flex flex-col iBTTiny:mx-auto md:m-0 iBTTiny:w-11/12 md:w-full">
  <div class="w-full flex flex-col">
    <div class="ism-gold text-1.75">Fleet Administration</div>
    <div id="_ADD-FLEET_" class="w-full flex items-center">
      <div class="text-1.5 text-white">Create New Fleet</div>
      <ism-svg data-tooltip="Create New Fleet" class="p-2 tooltip-tr" [name]="'plus'" (click)="createFleet()"></ism-svg>
    </div>

    <div id="_FLEETS_" class="w-full text-1.125">
      <div class="sticky z-10 top-[3px] bg-black bg-opacity-60">
        <div class="p-0 h-[2px] top-[3px] bg-white"></div>
        <div id="_Sticky_Header_" class="p-2 pl-2 sticky top-[3px] z-10 w-full tracking-wider flex items-center justify-between border border-white ism-gold text-1.125 bg-black bg-opacity-60">
          <div class="text-1.25 font-bold text-left">Fleet Name</div>
          <div class="text-1.25 font-bold text-right">System [ Hex ] &nbsp; Ships &nbsp; Orders &nbsp; Edit</div>
        </div>
        <div class="p-0 h-[1px] top-[3px] bg-white"></div>
      </div>

      <cdk-accordion class="w-full relative ism-accordion ism-accordion-border scrollbar-none">
        @for (fleet of fleets(); track $index) {
          <!-- @if(!fleet.turnDeactivated) { -->
          <pre><span class="hidden text-0.75">{{fleet|json}}</span></pre>
          <cdk-accordion-item class="ism-accordion-item ism-accordion-border" [ngClass]="{ amk: $index % 2 == 0 }" #accordionItem="cdkAccordionItem" role="button" [attr.id]="'header-' + $index" [attr.aria-expanded]="accordionItem.expanded" [attr.aria-controls]="'body-' + $index" (opened)="opened($index)">
            <div id="_Header-Fleets_" class="w-full flex items-center justify-between ism-accordion-header-border" (click)="accordionItem.toggle()">
              <div id="_Header-Chevron-Name_" class="py-2 flex items-center ism-white">
                <chevron-toggle [isOpen]="accordionItem.expanded" [classString]="'w-5 h-5  flex items-center ism-gold'"></chevron-toggle>
                <div data-tooltip="Fleet Name" class="font-bold text-1.25 text-clip ism-white tooltip-tr">{{ fleet.fleetName }}</div>
              </div>
              <div id="_Header-Detail_" class="flex items-center justify-end w text-1.25">
                <div class="px-1 ism-white">
                  <span data-tooltip="Star System ID" class="tooltip-tl"> {{ starSystemsById[fleet.starSystemId]?.number }} </span>
                  <span data-tooltip="System Hex" class="tooltip-tr"> &nbsp; [&nbsp;{{ fleet.locationHex }}&nbsp;]</span>
                </div>
                <div data-tooltip="Ship Count" class="px-1 ism-white tooltip-tr">&nbsp; &nbsp; &nbsp; &nbsp; {{ fleet.shipIds?.length }}</div>
                <div data-tooltip="Order Count" class="px-1 ism-white tooltip-tr">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{ fleetOrdersByFleetId[fleet._id]?.length || 0 }} &nbsp; &nbsp; &nbsp; &nbsp;</div>
                <a data-tooltip="Edit Fleet" type="button" class="px-2 tooltip-tl" (click)="editFleet($event, fleet)">
                  <ism-svg [name]="'pencilSquare'"></ism-svg>
                </a>
              </div>
            </div>

            <div id="_Detail-Ships-Orders_" class="ml-2 top-0 z-10 sticky text-1" role="region" [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'body-' + $index" [attr.aria-labelledby]="'header-' + $index">
              <cdk-accordion class="ml-2 flex flex-col">
                <cdk-accordion-item id="_Ships_" class="p-0.5" #accordionShips="cdkAccordionItem" role="button" [attr.id]="'ships-header-' + $index" [attr.aria-expanded]="accordionShips.expanded" [attr.aria-controls]="'ships-body-' + $index">
                  <div id="_Ship-Chevron_" class="py-1 flex items-center" (click)="accordionShips.toggle()">
                    <chevron-toggle [isOpen]="accordionShips.expanded" [classString]="'w-5 h-5 flex items-center ism-gold'"></chevron-toggle>
                    <div class="w-full flex items-center justify-between accordion-header">Ships ({{ fleet.shipIds?.length }})</div>
                  </div>

                  <div id="_Ship-List_" class="text-1" role="region" [style.display]="accordionShips.expanded ? '' : 'none'" [attr.id]="'ships-body-' + $index" [attr.aria-labelledby]="'ships-header-' + $index">
                    @if (fleet?.ships?.length > 0) {
                      <table class="ml-4 w-fit border">
                        <tr>
                          <th class="border border-separate bg-gray-800 bg-opacity-85">Ship Name</th>
                        </tr>
                        @for (ship of fleet?.ships; track ship) {
                          <tr class="data-odd-light">
                            <td class="text-left">{{ ship.shipName }}</td>
                          </tr>
                        }
                      </table>
                    }
                  </div>
                </cdk-accordion-item>

                <cdk-accordion-item id="_Orders_" class="p-0.5" #accordionOrders="cdkAccordionItem" role="button" [attr.id]="'orders-header-' + $index" [attr.aria-expanded]="accordionOrders.expanded" [attr.aria-controls]="'orders-body-' + $index">
                  <div id="_Order-Chevron_" class="py-1 flex items-center" (click)="accordionOrders.toggle()">
                    <chevron-toggle [isOpen]="accordionOrders.expanded" [classString]="'w-5 h-5 flex items-center ism-gold'"></chevron-toggle>
                    <div class="w-full flex items-center justify-between accordion-header">Fleet Orders ({{ fleetOrdersByFleetId[fleet._id]?.length || 0 }})</div>
                  </div>

                  <div id="_Order-List_" class="text-1" role="region" [style.display]="accordionOrders.expanded ? '' : 'none'" [attr.id]="'orders-body-' + $index" [attr.aria-labelledby]="'orders-header-' + $index">
                    @for (fleetOrder of fleetOrdersByFleetId[fleet._id] | orderBy: "sequence"; track fleetOrder._id) {
                      <fleet-order [fleetOrder]="fleetOrder"></fleet-order>
                    }
                  </div>
                </cdk-accordion-item>
              </cdk-accordion>
            </div>
          </cdk-accordion-item>
          <!-- } -->
        }
      </cdk-accordion>
    </div>
  </div>
</div>
