<div class="w-full relative text-white flex flex-grow flex-col flex-wrap scrollbar-none">
  <div class="p-2 font-bold ism-gold text-1.5">Fleet Orders</div>

  <div class="my-2 px-2 w-full flex items-center">
    <div class="text-1.5">Star Systems with Fleets</div>
    <div class="ml-2">
      <input class="ml-8 text-1.25 form-checkbox" type="checkbox" [(ngModel)]="showHistory" />
      Show History
    </div>
  </div>

  <cdk-accordion class="text-yellow-300 border border-yellow-300 flex flex-col box-border scrollbar-none">
    @for (informationStarSystem of informationStarSystems() | orderBy: "starSystemNumber"; track $index) {
      @let fleets = fleetsByStarSystemId[informationStarSystem.starSystemId] || [];
      @if (fleets.length > 0) {
        <cdk-accordion-item #accordionItem="cdkAccordionItem" class="text-yellow-300 border border-yellow-300" [attr.id]="'accordion-header-' + $index" [attr.aria-controls]="'accordion-body-' + $index" [attr.aria-description]="'Star System With Fleets - {{informationStarSystem.starSystemNumber}}'">
          <!-- @let starSystemId = informationStarSystem.starSystemId -->
          <div id="_Fleet-Summary_" class="w-full border flex items-center justify-between" [ngClass]="{ 'bg-gray-100 bg-opacity-25': $even }" (click)="accordionItem.toggle()" [attr.id]="'header-' + $index" [attr.aria-expanded]="accordionItem.expanded" [attr.aria-controls]="'body-' + $index" role="button">
            <div class="flex items-center">
              <chevron-toggle [isOpen]="accordionItem.expanded"></chevron-toggle>
              <div class="mx-2 tile-title">StarSystem Number: {{ informationStarSystem.starSystemNumber }}</div>
            </div>
            <div class="p-2">{{ fleets.length }} Fleets</div>
          </div>

          <div id="_Fleet-Detail_" role="region" [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'body-' + $index" [attr.aria-labelledby]="'header-' + $index">
            @for (fleet of fleets | orderBy: "fleetName"; track fleet._id) {
              <div id="_fleet-title_" class="pl-4 border-yellow-400 flex items-center justify-between">
                <div class="py-2 text-white whitespace-nowrap">{{ fleet.fleetName }} in {{ informationStarSystem.starSystemNumber }} at {{ fleet.locationHex }}</div>
                <!-- -->
                <div id="_fleet-mode-&-ship-count_" class="flex items-center">
                  @if (fleet.reserved) {
                    <div class="mx-2">{{ fleet.mode }}</div>
                  }
                  <div id="_Add-new-order_" class="mx-2 w-full text-right flex">
                    <div class="mx-2 ism-white">{{ fleet.ships.length }} ships</div>
                    <button id="_ADD_" type="button" data-tooltip="Add Order" class="mx-2 flex items-center" [disabled]="fleet.isPatrolling" (click)="add(fleet)" [ngClass]="{ 'tooltip-tl': !fleet.isPatrolling }">
                      @if (!fleet.isPatrolling) {
                        <ism-svg [name]="'plus'" [classString]="'w-8 h-8 flex items-center text-white hover:text-yellow-300 visited:text-white'"></ism-svg>
                      } @else {
                        <ism-svg [name]="'plus'" [classString]="'w-8 h-8 flex items-center text-gray-500 hover:text-gray-500 visited:text-gray-500'"></ism-svg>
                      }
                    </button>
                  </div>
                </div>
              </div>

              @for (fleetOrder of fleetOrdersByFleetId()[fleet._id]; track $index; let orderFirst = $first) {
                <div class="w-full border flex flex-col" [ngClass]="{ 'bg-gray-100 bg-opacity-25': $odd }">
                  <div class="m-2 flex items-center justify-between font-extralight ism-white">
                    <fleet-order [fleetOrder]="fleetOrder"></fleet-order>
                    <div class="flex items-center justify-end min-w-10">
                      @if (orderFirst && fleetOrder.complete === 0) {
                        <button id="_STOP_" type="button" data-tooltip="Stop/Interrupt Order" class="mx-2 text-white visited:text-white tooltip-tl hover:ism-gold" (click)="interrupt(fleet._id)">
                          <ism-svg [name]="'xSquare'" class-string="'w-8 h-8 flex items-center hover:text-white ism-gold'"></ism-svg>
                        </button>
                      }
                      <!-- -->
                      @if ($last && fleetOrder.complete === 0 && (fleetOrder?.elapsed || 0) === 0) {
                        <button id="_DELETE_" type="button" data-tooltip="Delete Order" class="mx-2 text-white visited:text-white tooltip-tl" (click)="delete(fleet._id)">
                          <ism-svg [name]="'trashcan'" class-string="'w-8 h-8 flex items-center hover:text-white ism-gold'"></ism-svg>
                        </button>
                      }
                    </div>
                  </div>
                </div>
              }
            }
          </div>
        </cdk-accordion-item>
      }
    }
  </cdk-accordion>
</div>
