<edit-dialog-custom (action)="signalAction($event)" [title]="title" [document]="fleetToEdit" [structureName]="structureName()" [structure]="rowDataDefault()" [templateCount]="1" class="flex flex-col" [templateXref]="selectXRef()">
  <ng-container edit-body> </ng-container>
</edit-dialog-custom>
<!--  -->
<ng-template #starSystemId>
  <select class="w-full h-full form-select rounded-r-md" [(ngModel)]="fleetToEdit.starSystemId">
    @for (sscr of starSystemCrossReference | orderBy: "starSystemNumber"; track sscr) {
      <option [ngValue]="sscr.starSystemId">{{ sscr.starSystemNumber }}</option>
    }
  </select>
</ng-template>
