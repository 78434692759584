import { Component, Signal, WritableSignal, effect, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Dialog } from '@angular/cdk/dialog';
import { tap } from 'rxjs';

import { authorizedMarshal } from '../../auth/auth.guard';
import { CampaignWithRace } from '../../base/campaign-race';

import { AreYouSure2Component } from '../../shared/are-you-sure2/are-you-sure2.component';
import { ShipEditComponent } from '../ship-edit/ship-edit.component';

import { FleetService } from '../../services/fleet.service';
import { ShipClassService } from '../../services/ship-class.service';
import { ShipService } from '../../services/ship.service';
import { TableService } from '../../services/table.service';

import { Fleet } from '../../interfaces/fleet';
import { Government } from '../../interfaces/government';
import { Ship } from '../../interfaces/ship';
import { ShipClass } from '../../interfaces/ship-class';

@Component({
  selector: 'ship-list',
  templateUrl: './ship-list.component.html',
})
export class ShipListComponent extends CampaignWithRace {
  authorizedMarshal: boolean = authorizedMarshal() || false;

  private shipClassService = inject(ShipClassService);
  private shipService = inject(ShipService);
  private fleetService = inject(FleetService);
  private tableService = inject(TableService);
  public dialog = inject(Dialog);

  government: Signal<Government | undefined>;
  shipClasses: Signal<ShipClass[]>;
  ships: WritableSignal<Ship[]> = signal([]);
  fleets: Signal<Fleet[]>;
  hashFleets: { [key: string]: Fleet; } = {};
  hashShips: { [key: string]: Ship; } = {};
  shipToFleetXRef: { [key: string]: string; } = {};
  shipClassFilter: ShipClass;
  filterValue: string = undefined;

  openCreate: boolean = false;
  selectedShipClass: ShipClass = undefined;
  quantity: number = 1;
  selectedFleet: Fleet = undefined;
  baseName: string;
  baseNumber: number = 1;

  constructor () {
    super();
    this.government = toSignal(this.tableService.getGovernmentById(this.race().governmentId, this.race().campaignId));
    this.shipClasses = toSignal<ShipClass[]>(this.shipClassService.getShipClassesForRace$(this.race()));
    this.shipService.getShipsForRaceId$(this.race()._id).subscribe(
      (ships => this.ships = signal<Ship[]>(ships))
    );
    this.fleets = toSignal(this.fleetService.getFleetsForRaceId$(this.race()._id));

    effect(() => {
      if (!this.fleets() || !this.ships()) {
        return;
      }
      this.hashShips = {};
      for (const ship of this.ships()) {
        this.hashShips[ship._id] = ship;
      }
      this.hashFleets = {};
      for (const fleet of this.fleets()) {
        this.hashFleets[fleet._id] = fleet;
        for (const shipId of fleet.shipIds) {
          this.shipToFleetXRef[shipId] = fleet._id;
        }
      }
    });
  };

  processSavedShip (savedShip: Ship) {
    // savedShip = this.updateItemCounts(savedShipClass);
    this.shipService.saveShip$(savedShip).pipe(
      tap((savedShip: Ship) => {
        // New shipClass will be added to the array
        // Edited shipClass will be updated in the array
        let found = false;
        this.ships.update(ships => {
          let newShips: Ship[] = ships.map(ship => {
            if (ship._id === savedShip._id) {
              found = true;
              return savedShip as Ship;
            }
            return ship;
          });
          if (!found) {
            newShips.push(savedShip);
          }
          return newShips;
        });
        return savedShip;
      })
    ).subscribe(
      () => {
        this.session.setNotifyMessage('Ship Saved');
        this.session.iAmNotBusy();
      }
    );
  };

  editShip (ship: Ship) {
    let shipToEdit = Object.assign({}, ship);
    const dialogRef = this.dialog.open<Ship>(ShipEditComponent, {
      width: '70%',
      height: '80%',
      panelClass: 'edit-dialog',
      data: {
        title: "Edit Ship",
        document: shipToEdit
      },
    });

    dialogRef.closed.subscribe(result => {
      if (result) {
        this.session.iAmBusy();
        let savedShip = result['document'];
        this.processSavedShip(savedShip);
      }
    });
  };

  deleteShip (ship: Ship): void {
    let shipClassToDelete = Object.assign({}, ship);
    const dialogRef = this.dialog.open<string>(AreYouSure2Component, {
      width: '65%',
      height: '75%',
      panelClass: 'edit-dialog',
      data: {
        // title: "Warning",
        // message: "This cannot be undone!",
        documentName: "Ship",
        document: shipClassToDelete
      },
    });

    dialogRef.closed.subscribe(result => {
      if (result) {
        this.session.iAmBusy();
        let shipId = result['document']['_id'];
        this.shipService.deleteShipForId$(shipId).pipe(
          tap(() => {
            this.ships.update(ships => ships.filter(
              ship => (ship._id !== shipId)
            ));
          })
        ).subscribe(
          () => {
            this.session.setNotifyMessage('Ship Deleted');
            this.session.iAmNotBusy();
          }
        );
      }
    });
  };

  private clearShipCreationValues () {
    this.selectedShipClass = null;
    this.baseName = '';
    this.baseNumber = 1;
    this.quantity = 0;
    this.selectedFleet = null;
  };

  createNewShips () {
    if (this && this.selectedShipClass && this.selectedFleet && this.quantity > 0) {
      let newShipBase: Ship = {
        campaignId: this.selectedShipClass.campaignId,
        raceId: this.selectedShipClass.raceId,
        shipClassId: this.selectedShipClass._id,
        shipName: this.selectedShipClass.className + '-0000',
        hullClass: this.selectedShipClass.hullClass || 'DD',
        turnMode: this.selectedShipClass.turnMode || 0,
        freighterFlag: this.selectedShipClass.freighterFlag || 0,
        actualSize: this.selectedShipClass.actualSize || 0,
        grade: this.government().grade,
        state: 'active',
        heading: 0,
        movementExpended: 0,
        battlesFought: 0,
        shipYardPoints: 0,
        turnActivated: this.session.campaign.turn + 1,
        turnDeactivated: 0,
        ssd: this.selectedShipClass.ssd || 'DUMMYSSD',
        maintenanceCost: this.selectedShipClass.maintenanceCost || 0,
        maintenanceOnBoard: 0,
        speedMaxStrategic: this.selectedShipClass.speedMaxStrategic || 0,
        speedCruiseStrategic: this.selectedShipClass.speedCruiseStrategic || 0,
        atmosphericCapability: this.selectedShipClass.atmosphericCapability || false,
        advancedManeuvering: this.selectedShipClass.advancedManeuvering || 0,
        xo: this.selectedShipClass.xo || 0,
        xog: this.selectedShipClass.xog || 0,
        outfit: this.selectedShipClass.outfit || '',
        fighterOutfit: this.selectedShipClass.fighterOutfit || '',
      };

      let newShips: Ship[] = [];
      let baseName = this.baseName || this.selectedShipClass.className;
      let number = this.baseNumber || 1;
      for (let i = 0; i < this.quantity; i++) {
        let newShip: Ship = Object.assign({}, newShipBase) as Ship;
        if (baseName != null && number > 0) {
          newShip.shipName = baseName + ' - ' + ('0000' + number++).slice(-4);
        }
        newShips.push(newShip);
      }
      this.session.iAmBusy();
      this.shipService.saveNewShips$(newShips).subscribe(
        (newShips) => {
          this.ships.update(ships => ships.concat(newShips));
          let newShipIds = newShips.map((ship) => {
            this.shipToFleetXRef[ship._id] = this.selectedFleet._id;
            return ship._id;
          });
          this.session.iAmNotBusy();

          this.selectedFleet['shipIds'] = [...(this.selectedFleet.shipIds || []), ...newShipIds];
          this.session.iAmBusy();
          this.fleetService.saveFleet$(this.selectedFleet).subscribe(
            () => {
              this.session.setNotifyMessage('Ships created, Fleet saved!');
              this.session.iAmNotBusy();
              this.clearShipCreationValues();
            }
          );
        }
      );
      this.openCreate = false;
    }
  };
};
