<edit-dialog (action)="signalAction($event)" [title]="title" class="flex flex-col">
  <ng-container edit-body>
    <div class="flex flex-col justify-center modal-center">
      <div id="_SUBTITLE_" class="mx-2 px-4 w-full text-left font-bold text-1.5 text-black">
        @if (fighterMode) {
          Fighter Bays:
        } @else {
          Magazine Points:
        }
      </div>
      <div id="_MAGAZINE-POINTS_" class="mx-2 w-full flex-col">
        @for (project of projects; track project) {
          <gray-label-wrapper [fieldLabel]="project.description + ' - ' + project.symbol" [fieldName]="project.symbol" class="w-full text-black flex items-center">
            <div id="input-number" class="w-full h-full bg-white flex items-center justify-between rounded-r-md">
              <input id="{{ project.symbol }}" name="{{ project.symbol }}" type="number" [(ngModel)]="outfitToEdit[project.symbol]" (change)="onChange(project)" min="0" max="{{ project.max }}" class="px-2 w-full h-full align-middle form-input rounded-r-md" />
              <div class="mx-2 my-1 px-2 py-1 w-1/4 text-left italic text-gray-700 whitespace-nowrap">(Max: {{ project.max }})</div>
            </div>
          </gray-label-wrapper>
        }
        <gray-label-wrapper [fieldLabel]="'Total'" [fieldName]="'total'" class="w-full text-black flex items-center">
          <div id="input-number" class="w-full h-full bg-white flex items-center rounded-r-md">{{ pointsUsed }} / {{ outfitPoints }}</div>
        </gray-label-wrapper>
      </div>

      @if (boatBayPoints > 0 && smallCraft.length > 0) {
        <hr class="border-yellow-400 border-1" />

        <div id="_BOAT-BAY-POINTS_" class="w-full flex-col justify-end">
          <div class="m-2 p-2 w-full font-bold text-black flex ism-black">Boat Bay Points:</div>
          @for (project of smallCraft; track project) {
            <gray-label-wrapper [fieldLabel]="project.description + ' - ' + project.symbol" [fieldName]="'smallcraft-' + $index" class="w-full text-black flex items-center">
              <div id="input-number" class="w-full h-full bg-white flex items-center justify-between rounded-r-md">
                <input class="mx-2 my-1 px-2 py-1 w-1/6 text-right font-bold ism-black" type="number" [(ngModel)]="outfitToEdit[project.symbol]" (change)="onBoatBayChange(project)" min="0" max="{{ project.max }}" />
                <div class="my-1 py-1 w-1/4 text-left italic ism-black text-ggray-700">(Max: {{ project.max }})</div>
              </div>
            </gray-label-wrapper>
          }
          <gray-label-wrapper [fieldLabel]="'Total'" [fieldName]="'smallcraft_total'" class="w-full text-black flex items-center">
            <div class="mx-2 px-2 text-left font-bold text-black">{{ boatBayPointsUsed | number: "1.0-0" }} / {{ boatBayPoints | number: "1.0-0" }}</div>
          </gray-label-wrapper>
        </div>
      }

      @if (xoMountCount > 0 && xoProjects.length > 0) {
        <hr id="_SEPARATOR_" class="border-yellow-400 border-1" />

        <div id="_XO-LOADOUT_" class="w-full flex flex-col ism-black">
          <div class="m-2 font-bold text-black text-1.5">External Ordinance (XO) Loadout</div>
          <table>
            <tr>
              <th class="text-black">Symbol</th>
              <th class="text-black">Count</th>
              <th class="text-black">Max</th>
              <th class="text-black">Mounts</th>
            </tr>
            @for (project of xoProjects; track project) {
              <tr>
                <td class="text-center ism-black">{{ project.symbol }}</td>
                <td class="ism-black">
                  <input class="text-right form-input ism-black" type="number" (change)="onXoChange(project)" min="0" [(ngModel)]="xoToEdit[project.symbol]" max="{{ project.xoMax }}" />
                </td>
                <td class="text-right ism-black">{{ project.xoMax }}</td>
                <td class="text-right ism-black">
                  {{ xoToEdit[project.symbol] * project.xoMountPoints || 0 }}
                </td>
              </tr>
            }
            <tr>
              <td class="text-right text-black" colspan="3">Total:</td>
              <td class="text-right text-black">{{ xoUsed }} / {{ xoMountCount }}</td>
            </tr>
          </table>
        </div>
      }
    </div>
  </ng-container>
</edit-dialog>
