<div id="_edit-dialog-shared_" class="flex flex-grow flex-col scrollbar-none">
  <div id="_modal-HEADER" class="flex flex-grow items-center justify-between modal-header">
    <div id="_icon-PageTitle_" class="flex items-center">
      <div id="_svgPurpleSaturn_" class="px-2 flex items-center justify-center">
        <ism-svg data-tooltip="Toggle Test Mode" class="tooltip-r" [name]="'saturnPurple'"></ism-svg>
      </div>
      <div class="w-11/12 text-left modal-title">{{ title() }}</div>
    </div>

    <div id="_Buttons" class="flex items-center justify-end">
      <button class="mx-2 px-4 btn" type="button" (click)="submit()">Save</button>
      <button class="px-4 btn" type="button" (click)="cancel()">Cancel</button>
    </div>
  </div>

  <ng-content select="[edit-body]"></ng-content>
  @if (ready()) {
    <div id="LIST_of-Rows_" cdkDropList [cdkDropListData]="derivedStructure()" (cdkDropListDropped)="rowDrop($event)" class="w-full flex flex-col iBTTiny:items-stretch lg:items-center ism-black dropContainer">
      <div cdkDropListGroup class="w-full text-1.125 modal-center flex flex-col scrollbar-none">
        <!-- FOR EACH ROW -->
        @for (row of derivedStructure(); track $index) {
          <div id="row-{{ $index }}" cdkDrag [cdkDragData]="row" cdkDropList [cdkDropListData]="row" (cdkDropListDropped)="fieldDrop($event)" class="px-1 w-full flex items-center justify-between ism-black dragRowItem dropContainer" [ngClass]="{ 'my-1 amkRed-lte': customize(), hidden: !customize() }">
            <!-- FOR EACH FIELD IN EACH ROW -->
            @for (field of row; track field.fieldName) {
              @let ref = (this.templateXref() || {})[field.modelName];
              <data-input [(fieldModel)]="document()[field.modelName]" [ismField]="field" [enableDragDrop]="customize()" [templateRef]="ref" class="py-0 w-full h-full flex items-center"></data-input>
            }
            <!-- Show if Row is empty and has no fields. -->
            <div class="w-fit items-center justify-end" [ngClass]="{ hidden: !customize() }">
              @if (row.length == 0) {
                <div class="px-2 w-full text-right italic text-gray-700 text-1">This row is empty. Drop a field here - row-{{ $index }}</div>
              }
              <div id="_row_drag-handle_" class="w-fit flex items-center rowDragHandle" cdkDragHandle>
                <ism-svg [name]="'dropHandle'"></ism-svg>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  }
  <!--  -->
  @else {
    <div>Not Quite Ready...</div>
  }

  <!-- For debug json data ACCORDION -->
  @if (showStructure) {
    <div class="text-black">
      <pre>{{ derivedStructure() | json }}</pre>
    </div>
  }
  @if (showData) {
    <div class="text-black">
      <pre>{{ document() | json }}</pre>
    </div>
  }

  <div id="_modal-FOOTER" class="flex items-center justify-between modal-footer">
    <div class="flex items-center">
      <!-- Show if not a player role -->
      @if (authorizedMarshal) {
        <div class="px-1 flex items-center justify-start relative bottom-0 text-left text-indigo-500 font-bold cursor-pointer text-1.25">
          <ism-svg [name]="'arrow-up-on-square-stack'" (click)="toggleCustomize()" data-tooltip="Toggle Customize Mode" class="mx-0 px-1 tooltip-tr"></ism-svg>
          <ism-svg [name]="'hamburger'" (click)="showData = !showData" data-tooltip="Show JSON Data" class="mx-0 px-1 tooltip-tr"></ism-svg>
          <ism-svg [name]="'squareStack-3d'" (click)="showStructure = !showStructure" data-tooltip="Show Structure" class="mx-0 px-1 tooltip-tr"></ism-svg>
          @if (customize()) {
            <ism-svg [name]="'checkmark'" (click)="saveStructure(); showStructure = false; toggleCustomize()" data-tooltip="Save Structure" class="mx-0 px-1 tooltip-tr"></ism-svg>
            <ism-svg [name]="'eyeSlash'" (click)="cancelModification(); showStructure = false; toggleCustomize()" data-tooltip="Revert Structure" class="mx-0 px-1 tooltip-tr"></ism-svg>
            <ism-svg [name]="'trashcan'" (click)="resetModification(); showStructure = false; toggleCustomize()" data-tooltip="Revert Structure" class="mx-0 px-1 tooltip-tr"></ism-svg>
          }
        </div>
      }
    </div>
    <div class="flex items-center justify-end">
      <button class="mx-2 px-4 btn" type="button" (click)="action.emit('save')">Save</button>
      <button class="px-4 btn" type="button" (click)="action.emit('cancel')">Cancel</button>
    </div>
  </div>
</div>
