<div class="w-full flex items-center justify-between">
  <div id="_ADD-FLEET_" class="flex items-center">
    <div class="text-1.5">{{ legendDisplay }}</div>
    <ng-content></ng-content>
  </div>
  <button data-tooltip="Toggle Legend Visibility" class="mb-2 mr-0 p-1 w-1/4 btn tooltip-tl" type="button" (click)="hideLegend = !hideLegend">Control Sheet Legend</button>
</div>

@if (!hideLegend) {
  <div id="_LEGEND_" class="w-full text-yellow-300 border-yellow-300 flex flex-col border-1">
    <!--  -->
    <div id="_LEGEND-LINE-1_" class="mx-2 flex items-center justify-between">
      <div id="_class-nameLabel_" class="px-2 w-1/4 flex items-center lanPixel2xl:whitespace-nowrap">CLASS NAME</div>
      <div id="_hull-classLabel_" class="w-1/4 text-center flex items-center justify-center lanPixel2xl:whitespace-nowrap">HULL CLASS</div>
      <div id="_ac-am-xo-xog_" class="w-1/4 flex items-center justify-center">
        <div class="px-2 lanPixel2xl:whitespace-nowrap">AC</div>
        <div class="px-2 lanPixel2xl:whitespace-nowrap">AM(#)</div>
        <div class="px-2 lanPixel2xl:whitespace-nowrap"># XO</div>
        <div class="px-2 lanPixel2xl:whitespace-nowrap"># XOg</div>
      </div>
      <div id="_hull-spaces_" class="w-1/4 text-right flex justify-end lanPixel2xl:whitespace-nowrap">HULL SPACES</div>
    </div>

    <div id="_LEGEND-LINE-2_" class="mx-2 flex items-center justify-between">
      <div id="_turn-mode_" class="px-2">[Turn Mode]</div>
      <div id="_SSD_" class="text-center">SSD</div>
      <div id="_speed_max-cruise_" class="text-right">[Max / Cruise]</div>
    </div>

    <div id="_LEGEND-LINE-3_" class="mx-2 flex items-center justify-between">
      <div class="px-2">Outfit</div>
      <div class="text-right">Fighter Outfit</div>
    </div>

    <div id="_LEGEND-LINE-4_" class="mx-2 flex justify-between">
      <div class="px-2 flex-1 flex items-center justify-stretch">
        <div class="ism-gold flex-1">Build Costs</div>
        <div class="px-2 lanPixel2xl:whitespace-nowrap flex-1">Maintenance Fund</div>
      </div>
      <div class="flex-1 flex items-center justify-between">
        <div class="px-2 text-center lanPixel2xl:whitespace-nowrap">Missile Fund</div>
        <div class="px-2 text-center lanPixel2xl:whitespace-nowrap">Fighter Fund</div>
        <div class="pl-2 text-right lanPixel2xl:whitespace-nowrap">Small Craft Cost</div>
      </div>
    </div>
  </div>
}
