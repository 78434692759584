import { Component } from '@angular/core';

import { DialogHandlerComponent } from '../../shared/dialog-handler/dialog-handler.component';

import { ShipClass } from '../../interfaces/ship-class';

import structure from './ship-class-edit-structure.json';

@Component({
  selector: 'ship-class-edit',
  templateUrl: './ship-class-edit.component.html',
})
export class ShipClassEditComponent extends DialogHandlerComponent {
  title: string = 'Ship Class Edit';
  shipClassToEdit!: ShipClass;

  constructor () {
    super();

    this.importedRowFieldStructure.set(structure);
    this.structureName.set("ShipClassEditFields");
    this.shipClassToEdit = Object.assign({}, this.data.document as ShipClass);
  };

  override save () {
    this.dialogRef.close({
      documentName: "ShipClass",
      document: this.shipClassToEdit
    });
  };
};
