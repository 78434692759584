import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkAccordionModule } from "@angular/cdk/accordion";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { FormsModule } from "@angular/forms";

import { QuickHtmlModule } from '../quick-html/quick-html.module';
import { SharedModule } from '../shared/shared.module';

// import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
// import { RouterModule } from '@angular/router';

import { EditDialogCustomComponent } from './edit-dialog-custom/edit-dialog-custom.component';

@NgModule({
  declarations: [
    EditDialogCustomComponent
  ],
  exports: [
    EditDialogCustomComponent,
  ],
  imports: [
    CommonModule,
    CdkAccordionModule,
    DragDropModule,
    FormsModule,
    QuickHtmlModule,
    SharedModule,
  ]
})
export class CommonJoinedModule { }
