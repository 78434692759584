import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authenticated } from '../auth/auth.guard';

import { FleetAdministrationComponent } from './fleet-administration/fleet-administration.component';
import { FleetOrdersComponent } from './fleet-orders/fleet-orders.component';
import { SharedShipClassesComponent } from './shared-ship-classes/shared-ship-classes.component';
import { ShipClassesComponent } from './ship-classes/ship-classes.component';
import { ShipListComponent } from './ship-list/ship-list.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [authenticated],
    children: [
      {
        path: 'fleet-orders',
        component: FleetOrdersComponent,
      },
      {
        path: 'fleet-administration',
        component: FleetAdministrationComponent,
      },
      {
        path: 'ship-administration',
        component: ShipListComponent,
      },
      {
        path: 'class-designs',
        component: ShipClassesComponent,
      },
      {
        path: 'shared-designs',
        component: SharedShipClassesComponent,
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdmiraltyRoutingModule { }
