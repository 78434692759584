import { Component, Input } from '@angular/core';

@Component({
  selector: 'ship-class-title-and-legend',
  templateUrl: './ship-class-title-and-legend.component.html'
})
export class ShipClassTitleAndLegendComponent {
  hideLegend: boolean = false;
  @Input() legendDisplay: string = "Legend";
}
