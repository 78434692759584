import { Component, OnInit } from '@angular/core';

import { DialogHandlerComponent } from '../../shared/dialog-handler/dialog-handler.component';

import { ShipClass } from '../../interfaces/ship-class';

@Component({
  selector: 'outfit-edit',
  templateUrl: './outfit-edit.component.html'
})
export class OutfitEditComponent extends DialogHandlerComponent implements OnInit {
  title: string = 'Outfit Editor';
  shipClass: ShipClass;
  outfitToEdit: {};
  xoToEdit: {};

  xoMountCount: number = 0;
  mgCount: number = 0;
  vCount: number = 0;
  boatBayPoints: number = 0;

  pointsUsed: number = 0;
  outfitPoints: number = 0;
  boatBayPointsUsed: number = 0;
  xoUsed: number = 0;

  fighterMode: boolean;
  projects: any[];
  smallCraft: any[];
  xoProjects: any[];
  fighterProjects: any[];

  constructor () {
    super();
    this.shipClass = this.data['document'] as ShipClass;
    this.xoMountCount = this.shipClass['xo'];
    this.mgCount = this.shipClass['mgCount'];
    this.vCount = this.shipClass['vCount'];
    this.boatBayPoints = this.shipClass['boatBayPoints'];

    this.outfitToEdit = Object.assign({}, this.data['outfitToEdit']);
    this.xoToEdit = Object.assign({}, this.data['xoToEdit']);
    this.fighterMode = this.data['fighterMode'] as boolean;
    this.projects = this.data['projects'] as any[];
    this.smallCraft = this.data['smallCraft'] as any[];
    this.xoProjects = this.data['xoProjects'] as any[];
    this.fighterProjects = this.data['fighterProjects'] as any[];

    this.outfitPoints = this.vCount;
    if (this.fighterMode) {
      this.title = 'Fighter Outfit Editor';
      this.projects = this.fighterProjects;
      this.smallCraft = [];
      this.xoProjects = [];
    }
    else {
      this.outfitPoints = (this.mgCount || 0) * 200;
    }
  };

  ngOnInit (): void {
    this.onChange();
    this.onBoatBayChange();
    this.onXoChange();
  };

  private valueForNotationType (project: {}, type: string) {
    let value: number = 0;
    let notations = (project['notations'] as string).split(',').join('=').split('=');
    let index = notations.indexOf(type);
    if (index > -1) {
      value = notations[index + 1] as unknown as number;
    }
    return value;
  };

  private calcProjectMax () {
    for (const project of this.projects) {
      let cargoPointsPer = project.cargoPoints || 1;
      let allPointsNotUsed = this.outfitPoints - this.pointsUsed;
      let projectPointsUsed = (this.outfitToEdit[project.symbol] || 0) * cargoPointsPer;
      project.max = Math.trunc((allPointsNotUsed + projectPointsUsed) / cargoPointsPer);
    }
  };

  private calcSmallCraftMax () {
    for (const project of this.smallCraft) {
      let value = this.valueForNotationType(project, 'tSC');
      project.max = Math.trunc((this.boatBayPoints - this.boatBayPointsUsed + ((this.outfitToEdit[project.symbol] || 0) * value)) / value);
    }
  };

  private calcXoProjectMax () {
    for (const project of this.xoProjects) {
      project.xoMax = Math.trunc((this.xoMountCount - this.xoUsed + ((this.xoToEdit[project.symbol] || 0) * (project.xoMountPoints || 0))) / (project.xoMountPoints || 1));
    }
  };

  private isChangeValid (project: {}, editObject: {}) {
    let isChangeValid = true;
    if (project) {
      const count = editObject[project['symbol']];
      isChangeValid = (!!count || count === 0);
    }
    return isChangeValid;
  };

  onChange (projectUnderReview = undefined) {
    if (!this.isChangeValid(projectUnderReview, this.outfitToEdit)) {
      return;
    }

    this.pointsUsed = 0;
    for (const symbol in this.outfitToEdit) {
      if (Object.hasOwnProperty.call(this.outfitToEdit, symbol)) {
        const count = this.outfitToEdit[symbol];
        let project = this.projects.find(proj => proj.symbol === symbol);
        if (project) {
          this.pointsUsed += count * (project.cargoPoints || 1);
        }
        if (!count) {
          delete this.outfitToEdit[symbol];
        }
      }
    }
    this.calcProjectMax();
  };

  onBoatBayChange (projectUnderReview = undefined) {
    if (!this.isChangeValid(projectUnderReview, this.outfitToEdit)) {
      return;
    }

    this.boatBayPointsUsed = 0;
    for (const symbol in this.outfitToEdit) {
      if (Object.hasOwnProperty.call(this.outfitToEdit, symbol)) {
        const count = this.outfitToEdit[symbol];
        let project = this.smallCraft.find(proj => proj.symbol === symbol);
        if (project) {
          let value = this.valueForNotationType(project, 'tSC');
          this.boatBayPointsUsed += count * value;
        }
        if (!count) {
          delete this.outfitToEdit[symbol];
        }
      }
    }
    this.calcSmallCraftMax();
  };

  onXoChange (projectUnderReview = undefined) {
    if (!this.isChangeValid(projectUnderReview, this.xoToEdit)) {
      return;
    }

    this.xoUsed = 0;
    let newEdit = {};
    for (const symbol in this.xoToEdit) {
      if (Object.hasOwnProperty.call(this.xoToEdit, symbol)) {
        const count = this.xoToEdit[symbol];
        let project = this.xoProjects.find(proj => proj.symbol === symbol);
        if (project) {
          this.xoUsed += count * (project.xoMountPoints || 1);
        }
        if (count) {
          newEdit[symbol] = count;
        }
      }
    }
    this.xoToEdit = newEdit;
    this.calcXoProjectMax();
  };

  override save () {
    this.dialogRef.close({
      documentName: "Outfit",
      document: this.shipClass,
      outfitToEdit: this.outfitToEdit,
      xoToEdit: this.xoToEdit
    });
  };
}
