<div id="_SHIPLIST-CENTER_" class="w-full relative flex flex-col text-1.25">
  <div class="ism-gold text-1.75">Ship Administration</div>

  <!-- Debug Quick Ship Creator - Intended for administration / iBM shortcut only -->
  @if (authorizedMarshal) {
    <div id="_top-create-new-ship_" class="mb-2 w-full h-full flex items-center justify-between">
      <div class="w-11/12 h-full flex items-center">
        <div class="mx-0 text-1.5 text-white flex flex-col items-center md:flex-row">
          <div class="whitespace-nowrap">Create&nbsp;</div>
          <div class="whitespace-nowrap">New Ship(s)</div>
        </div>
        <ism-svg data-tooltip="Toggle Add New Ship(s)" class="mx-4 flex items-center tooltip-tr" [name]="'plus'" (click)="openCreate = !openCreate"></ism-svg>
      </div>
      <div id="_FilterBy-ShipClass_" class="w-full h-full text-black flex flex-row">
        <div class="px-2 w-full h-full flex items-center justify-end">
          <div for="shipClassFilter" class="px-2 flex flex-col md:flex-row items-center text-1.25 ism-white h-full">
            <div class="whitespace-nowrap">Filter By&nbsp;</div>
            <div class="whitespace-nowrap">Ship Class:</div>
          </div>

          <div class="w-full bg-white flex items-center">
            <select id="shipClassFilter" [(ngModel)]="filterValue" class="w-full h-full form-select">
              <option class="ism-black" [ngValue]="undefined">None</option>
              @for (shipClass of shipClasses(); track shipClass) {
                <option class="ism-black" [ngValue]="shipClass._id">{{ shipClass.className }}</option>
              }
            </select>
          </div>
        </div>
      </div>
    </div>
    @if (openCreate) {
      <div class="w-full text-1.125">
        <table class="w-full border-0">
          <tr class="data">
            <td class="text-right">Ship Class:</td>
            <td>
              <select id="shipClass" class="px-2 w-full font-bold text-black" [(ngModel)]="selectedShipClass">
                @for (shipClass of shipClasses(); track shipClass) {
                  <option class="ism-black" [ngValue]="shipClass">{{ shipClass.className }}</option>
                }
              </select>
            </td>
          </tr>
          <tr>
            <td class="text-right">Base Name:</td>
            <td>
              <input id="baseName" class="px-2 w-full font-bold text-black" [(ngModel)]="baseName" placeholder="{{ selectedShipClass?.className || 'NewShip' }}" type="text" />
            </td>
          </tr>
          <tr>
            <td class="text-right">Base Number:</td>
            <td>
              <input id="baseNumber" class="px-2 w-full font-bold text-black" [(ngModel)]="baseNumber" type="number" min="1" />
            </td>
          </tr>
          <tr>
            <td colspan="2" class="">First ship will be named {{ baseName }}-{{ ("0000" + baseNumber).slice(-4) }}</td>
          </tr>
          <tr>
            <td class="text-right">Quantity:</td>
            <td>
              <input id="quantity" class="px-2 w-full font-bold text-black" [(ngModel)]="quantity" type="number" min="1" />
            </td>
          </tr>
          <tr>
            <td class="text-right">Fleet:</td>
            <td>
              <select id="fleetSelect" class="px-2 w-full font-bold text-black" required [(ngModel)]="selectedFleet">
                @for (fleet of fleets(); track $index) {
                  <option [ngValue]="fleet">{{ fleet.fleetName }}</option>
                }
              </select>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <div class="flex justify-end">
                <button class="mx-2 px-2 btn" type="button" (click)="createNewShips()">Save</button>
              </div>
            </td>
          </tr>
        </table>
      </div>
    }
  }

  <div id="_ship-list-table_" class="w-full sticky z-30 top-[3.5rem] text-1.125">
    <table id="_SHIPS_" class="w-full">
      <thead id="_sticky-thead_" class="top-0 z-30 sticky bg-black bg-opacity-60">
        <tr>
          <th class="p-0 h-[2px] bg-white" colspan="8"></th>
        </tr>
        <tr>
          <th class="border text-1.125 text-center ism-gold bg-black bg-opacity-60">Name</th>
          <th class="border text-1.125 text-center ism-gold bg-black bg-opacity-60">Fleet</th>
          <th class="border text-1.125 text-center ism-gold bg-black bg-opacity-60">Class / Size</th>
          <th class="border text-1.125 text-center ism-gold bg-black bg-opacity-60">SSD-String</th>
          <th class="border text-1.125 text-center ism-gold bg-black bg-opacity-60">Turn Mode</th>
          <th class="border text-1.125 text-center ism-gold bg-black bg-opacity-60">Speed</th>
          <th class="border text-1.125 text-center ism-gold bg-black bg-opacity-60">Outfit</th>
          <th class="border text-1.125 text-center ism-gold bg-black bg-opacity-60">Actions</th>
        </tr>
        <tr>
          <th class="p-0 h-[2px] bg-white" colspan="8"></th>
        </tr>
      </thead>
      <tbody>
        @for (ship of ships() | filterKeyValue: "shipClassId" : filterValue | orderBy: "shipName"; track ship) {
          <tr class="data-even-light">
            <td data-tooltip="Ship Name" class="text-1.125 tooltip-tr">{{ ship.shipName }}</td>
            <td data-tooltip="Fleet Name" class="text-1.125 tooltip-tr break-normal">{{ hashFleets[shipToFleetXRef[ship._id]]?.fleetName }}</td>
            <!-- <td>{{hashFleets[ship.fleetId].fleetName}}</td> -->
            <td data-tooltip="Hull Class" class="text-1.125 tooltip-tr">{{ ship.hullClass }} / {{ ship.actualSize }}</td>
            <td data-tooltip="SSD-String" class="text-1.125 tooltip-tr">{{ ship.ssd }}</td>
            <td data-tooltip="Turn Mode" class="w-[6%] text-1.125 text-center tooltip-tr">{{ ship.turnMode }}</td>
            <td data-tooltip="Speed:Max/Cruise" class="w-[6%] text-1.125 text-center tooltip-tl">
              {{ ship.speedMaxStrategic }}
              @if (ship.speedCruiseStrategic > 0) {
                &nbsp;/&nbsp;{{ ship.speedCruiseStrategic }}
              }
            </td>
            <td class="text-1.125 break-normal">
              <span data-tooltip="Outfit" class="tooltip-tr">
                {{ ship.outfit | outfitKeyValue }}
              </span>
              @if (ship.fighterOutfit) {
                <span data-tooltip="Fighter Outfit" class="text-1.125 tooltip-tl">
                  <hr class="border-yellow-300" />
                  {{ ship.fighterOutfit | outfitKeyValue }}
                </span>
              }
            </td>
            <td class="w-[8%]">
              <div id="_ICON-SET_" class="flex items-center justify-around">
                <a id="_edit-ship_" data-text="Edit" class="mx-1 flex items-center" type="button" (click)="editShip(ship)">
                  <ism-svg data-tooltip="Edit Ship" class="tooltip-tl" [name]="'pencilSquare'"></ism-svg>
                </a>
                @if (!hashFleets[ship.fleetId]) {
                  <a id="_delete-ship_" data-text="Delete" type="button" class="flex items-center mx-1t" (click)="deleteShip(ship)">
                    <ism-svg data-tooltip="Delete Ship" class="tooltip-tl" [name]="'trashcan'"></ism-svg>
                  </a>
                }
              </div>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
</div>
