import { Component, signal, WritableSignal } from '@angular/core';

import { DialogHandlerComponent } from '../../shared/dialog-handler/dialog-handler.component';

import { FleetOrder } from '../../interfaces/fleet-order';
import { Star, Planet, StarSystem } from '../../interfaces/star-system';
import { InformationWarpPoint } from '../../interfaces/information-warp-point';
import { Ship } from '../../interfaces/ship';
import { Fleet } from '../../interfaces/fleet';

@Component({
  selector: 'app-fleet-order-edit',
  templateUrl: './fleet-order-edit.component.html'
})
export class FleetOrderEditComponent extends DialogHandlerComponent {
  fleetOrder: FleetOrder;
  instructions: any[];
  starSystemById: { [key: string]: StarSystem; };
  stars: Star[];
  planets: Planet[];
  informationWarpPoints: InformationWarpPoint[];
  ships: Ship[];
  fleets: Fleet[];
  fleet: Fleet;
  options: { survey: string[]; binary: string[]; binaryPlus: string[]; loiterType: string[]; } = {
    survey: ['Rough', 'Detailed'],
    binary: ['Primary', 'Secondary'],
    binaryPlus: ['Primary', 'Secondary', 'Both'],
    loiterType: ['End of Turn', 'Duration']
  };
  selectedInstruction: WritableSignal<any> = signal(undefined);
  element: any;
  title: string;

  constructor () {
    super();
    this.fleetOrder = this.data['document'] as FleetOrder;
    this.instructions = this.data['instructions'];
    this.starSystemById = this.data['starSystemById'];
    this.title = this.data['documentName'];
    this.informationWarpPoints = this.data['informationWarpPoints'] || [];
    this.fleets = this.data['fleets'];

    let starSystem: StarSystem = this.starSystemById[this.fleetOrder.endStarSystemId];
    this.stars = starSystem.stars || [];
    this.planets = [];
    if (this.stars.length > 0) {
      this.planets = this.stars[0].planets;
      if (this.stars.length > 1) {
        this.planets = this.planets.concat(this.stars[1].planets);
      }
    }
    for (const iwp of this.informationWarpPoints) {
      iwp['destString'] = '';
      if (iwp.turnExplored >= iwp.turn) {
        iwp['destString'] = ' to ' + iwp.destinationSystemNumber + '@' + iwp.destinationHex;
      }
    }
    let index = this.fleets.findIndex(f => f._id === this.fleetOrder.fleetId);
    if (index > -1) {
      this.fleet = this.fleets[index];
      this.fleets.splice(index, 1);
      this.ships = this.fleet.ships;
    }

    if (this.fleetOrder.code) {
      this.selectedInstruction.set(this.instructions.find(instruction => instruction.code === this.fleetOrder.code));
    }
  };

  override save () {
    this.fleetOrder.code = this.selectedInstruction().code;
    if (this.element) {
      this.fleetOrder.element = this.element._id;
      this.fleetOrder.hex = this.element.strategicHex;
    }
    if (this.fleetOrder.code === 161 && this.fleetOrder.stage === 2 && this.fleetOrder.hex.length >= 4) {
      this.fleetOrder.stage = 3;
    }
    this.dialogRef.close({
      documentName: "FleetOrder",
      document: this.fleetOrder
    });
  };
};
