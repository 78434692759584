import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FleetOrder } from '../interfaces/fleet-order';
import { FleetInstruction } from '../interfaces/fleet-instruction';
import { Fleet } from '../interfaces/fleet';
import { InformationWarpPoint } from '../interfaces/information-warp-point';
import { Ship } from '../interfaces/ship';

@Injectable({
  providedIn: 'root'
})
export class FleetOrderService {
  constructor (
    private http: HttpClient,
  ) { };

  getFleetOrdersForRaceId (raceId: string): Observable<FleetOrder[]> {
    return this.http.get<FleetOrder[]>('/api/fleetOrder/race/' + raceId);
  };

  getAllFleetOrdersForRaceId (raceId: string): Observable<FleetOrder[]> {
    return this.http.get<FleetOrder[]>('/api/fleetOrder/race/' + raceId + '/all');
  };

  getFleetOrdersForFleetId (fleetId: string): Observable<FleetOrder[]> {
    return this.http.get<FleetOrder[]>('/api/fleetOrder/fleet/' + fleetId);
  };

  getFleetOrderForId (fleetOrderId: string): Observable<FleetOrder> {
    return this.http.get<FleetOrder>('/api/fleetOrder/' + fleetOrderId);
  };

  saveFleetOrder (fleetOrder: FleetOrder) {
    if (fleetOrder._id != null) {
      return this.http.put<FleetOrder>('/api/fleetOrder/' + fleetOrder._id, fleetOrder);
    } else {
      return this.saveNewFleetOrder(fleetOrder);
    }
  };

  saveNewFleetOrder (fleetOrder: FleetOrder) {
    return this.http.post<FleetOrder>('/api/fleetOrder', fleetOrder);
  };

  deleteFleetOrder (fleetOrder: FleetOrder) {
    return this.http.delete('/api/fleetOrder/' + fleetOrder._id);
  };

  buildDisplay (
    fleetOrder: FleetOrder,
    fleet: Fleet,
    selectedInstruction: FleetInstruction,
    targetFleet: Fleet,
    informationWarpPoint: InformationWarpPoint
  ) {
    let shipById: { [key: string]: Ship; } = fleet.ships.reduce(
      (hash, ship) => (hash[ship._id] = ship as Ship, hash), {}
    );
    let getShipNames = function (shipIds: string[]) {
      let shipNamesArray = shipIds.map(shipId => shipById[shipId].shipName);
      let shipNames = shipNamesArray.join(', ');
      return shipNames;
    };

    let pending = fleetOrder.complete === 0;
    // let selectedInstruction = this.hashInstructions()[fleetOrder.code];
    let fleetOrderString = selectedInstruction.message + ' ';

    if ([111, 411, 501].includes(fleetOrder.code)) {
      if (targetFleet) {
        fleetOrderString += targetFleet.fleetName + ' ';
      }
      else {
        fleetOrderString += 'a fleet ';
      }
    }

    if ([102, 103, 104, 111].includes(fleetOrder.code)) {
      fleetOrderString += 'at hex ';
    }

    if (fleetOrder.code === 111) {
      fleetOrderString += targetFleet.locationHex + ' ';
    }
    else if (fleetOrder.code <= 131) {
      fleetOrderString += fleetOrder.hex + ' ';
    }

    if (fleetOrder.code === 181 || fleetOrder.code === 191) {
      if (pending) {
        let shipNames = getShipNames(fleetOrder.things);
        fleetOrderString += '(' + shipNames;
      }
      else {
        fleetOrderString += '(' + fleetOrder.things.length + ' ships';
      }
      fleetOrderString += ') as Fleet ' + fleetOrder.note + ' and transit Warp Point at hex ' + fleetOrder.hex + ' ';
    }

    if (informationWarpPoint) {
      if (fleetOrder.code === 103 || fleetOrder.code === 181 || fleetOrder.code === 191) {
        fleetOrderString += 'leading to ';
      }
      else if (fleetOrder.code === 161 || fleetOrder.code === 171) {
        fleetOrderString += 'of ';
      }
      if (informationWarpPoint.turnExplored > 0) {
        fleetOrderString += 'star system ' + informationWarpPoint.warpPoint?.destination + ' ';
      }
      else {
        fleetOrderString += 'an unknown star system ';
      }
    }

    if (fleetOrder.code === 401) {
      if (pending) {
        let shipNames = getShipNames(fleetOrder.things);
        fleetOrderString += '(' + shipNames;
      }
      else {
        fleetOrderString += '(' + fleetOrder.things.length + ' ships';
      }
      fleetOrderString += ') as new fleet ' + fleetOrder.note;
    }

    if (selectedInstruction.options === "binary" || selectedInstruction.options === "binaryPlus") {
      fleetOrderString += 'for ' + fleetOrder.option +
        (fleetOrder.option === 'Both' ? ' stars ' : ' star ');
    }
    else if (selectedInstruction.options === "survey" || selectedInstruction.options === "loiterType") {
      fleetOrderString += fleetOrder.option + ' ';
    }

    if (fleetOrder.code === 511 && fleetOrder.option && fleetOrder.hex) {
      fleetOrderString += fleetOrder.hex + ' hours';
    }

    if (fleetOrder.code != 201 && fleetOrder.code < 401) {
      fleetOrderString += 'using speed ' + fleetOrder.speed + this.buildStageString(fleetOrder);
    }

    if ((fleetOrder.code === 161 || fleetOrder.code === 181) && fleetOrder.stage >= 3) {
      fleetOrderString += ', Probe Route: ' + fleetOrder.hex;
    }

    if (fleetOrder.estimate && fleetOrder.estimate != Number.POSITIVE_INFINITY) {
      fleetOrderString += ', estimate: ' + fleetOrder.estimate + ' hours';
    }

    fleetOrder.asString = fleetOrderString;
    return fleetOrderString;
  };

  buildStageString (fleetOrder: FleetOrder) {
    let stageString = '';
    if (fleetOrder.complete === 0 && fleetOrder.stage > 0) {
      switch (fleetOrder.code) {
        case 161:
          if (fleetOrder.stage === 1) {
            stageString = ', transit warp point';
          }
          else if (fleetOrder.stage === 2) {
            stageString = ', determining best route';
          }
          else if (fleetOrder.stage === 3) {
            stageString = ', traveling determined route';
          }
          else if (fleetOrder.stage === 4) {
            stageString = ', transit warp point back to origin';
          }
          break;
        case 171:
          if (fleetOrder.stage === 1) {
            stageString = ', transit warp point';
          }
          else if (fleetOrder.stage === 2 || fleetOrder.stage === 3) {
            stageString = ', gathering data';
          }
          else if (fleetOrder.stage === 4) {
            stageString = ', transit warp point back to origin';
          }
          break;
        case 301:
        case 302:
        case 303:
        case 304:
          if (fleetOrder.stage === 1) {
            stageString = ', positioning';
          }
          else if (fleetOrder.stage === 2) {
            stageString = ', surveying';
          }
          break;
        default:
          stageString = ', stage: ' + fleetOrder.stage;
      }
    }
    return stageString;
  };
}
