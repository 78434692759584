import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomOption } from '../interfaces/custom-option';

/*
  router.get( '/customOption/race/:race_id'
  router.get( '/customOption/race/:race_id/save/:save_num'
  router.get( '/customOption/:option_name'
  router.get( '/customOption/:option_name/:option_version'
  router.get( '/customOption/campaign/:campaign_id/:option_name'
  router.put( '/customOption'
  router.post( '/customOption'
  router.delete( '/customOption/race/:race_id/save/:save_num'
  router.delete( '/customOption/:option_id'
 */

@Injectable({
  providedIn: 'root'
})
export class CustomOptionService {
  private http = inject(HttpClient);

  getCustomOptionForCampaignAndName$ (campaignId: string, name: string): Observable<CustomOption | undefined> {
    return this.http.get<CustomOption>("/api/customOption/campaign/" + campaignId + "/" + name);
  };

  saveCustomOption$ (customOption: CustomOption): Observable<CustomOption> {
    return this.http.post<CustomOption>("/api/customOption", customOption);
  };

  deleteCustomOptionForId$ (optionId: string): Observable<CustomOption> {
    return this.http.delete<CustomOption>("/api/customOption/" + optionId);
  };
}
