<edit-dialog (action)="signalAction($event)" [title]="title" class="flex flex-col">
  <ng-container edit-body class="text-black">
    @let defineRoute = (fleetOrder.code === 161 || fleetOrder.code === 181) && fleetOrder.stage === 2;
    @if (defineRoute) {
      <div id="_modal-CENTER" class="w-full flex flex-col modal-center">
        <gray-label-wrapper [fieldLabel]="'Instruction'" [fieldName]="'instruction'" class="w-full text-black flex items-center">
          <span class="mx-4 px-4 form-input ism-black">{{ selectedInstruction().message }}</span>
        </gray-label-wrapper>
        <!-- -->
        <gray-label-wrapper [fieldLabel]="'Hex Array'" [fieldName]="'hex_array'" class="w-full text-black flex items-center">
          <input class="mx-4 px-4 form-input ism-black" type="text" [(ngModel)]="fleetOrder.hex" required />
        </gray-label-wrapper>
        <!-- -->
        <gray-label-wrapper [fieldLabel]="'Speed'" [fieldName]="'speed'" class="w-full text-black flex items-center">
          <input class="mx-4 px-2 form-input ism-black" type="text" [(ngModel)]="fleetOrder.speed" required />
        </gray-label-wrapper>
      </div>
    } @else {
      <div id="_modal-CENTER" class="w-full flex flex-col modal-center">
        <gray-label-wrapper [fieldLabel]="'Instruction'" [fieldName]="'instruction'" class="w-full text-black flex items-center">
          <select class="mx-4 px-4 form-select ism-black" [(ngModel)]="selectedInstruction">
            @for (instruction of instructions | orderBy: "code"; track $index) {
              <option [ngValue]="instruction" [disabled]="!instruction['good']">{{ instruction["message"] }}</option>
            }
          </select>
        </gray-label-wrapper>
        <!-- -->
        @if (selectedInstruction().code === 101) {
          <gray-label-wrapper [fieldLabel]="'Hex'" [fieldName]="'hex'" class="w-full text-black flex items-center">
            <input class="mx-2 px-4 form-input ism-black" type="text" [(ngModel)]="fleetOrder.hex" required />
          </gray-label-wrapper>
        }
        <!-- -->
        @if (selectedInstruction().code === 102) {
          <gray-label-wrapper [fieldLabel]="'Planet'" [fieldName]="'planet'" class="w-full text-black flex items-center">
            <select class="mx-4 px-6 text-left form-select ism-black" [(ngModel)]="element">
              @for (planet of planets | orderBy: "locator"; track $index) {
                <option [ngValue]="planet">{{ planet["locator"] }}</option>
              }
            </select>
          </gray-label-wrapper>
        }
        <!-- -->
        @if (selectedInstruction().code === 111 || selectedInstruction().code === 411 || selectedInstruction().code === 501) {
          <gray-label-wrapper [fieldLabel]="'Fleet'" [fieldName]="'fleet'" class="w-full text-black flex items-center">
            <select class="mx-4 px-6 text-left form-select ism-black" [(ngModel)]="element">
              @for (fleet of fleets | orderBy: "fleetName"; track $index) {
                <option [ngValue]="fleet">{{ fleet["fleetName"] }}</option>
              }
            </select>
          </gray-label-wrapper>
        }
        <!-- -->
        @if (selectedInstruction().code === 181 || selectedInstruction().code === 191 || selectedInstruction().code === 401) {
          <gray-label-wrapper [fieldLabel]="'Ships for ' + (selectedInstruction().code === 401) ? 'Split' : 'Probe'" [fieldName]="'ships'" class="w-full text-black flex items-center">
            <select class="mx-4 px-6 form-select ism-black" [(ngModel)]="fleetOrder.things" multiple="true">
              @for (ship of ships | orderBy: "shipName"; track $index) {
                <option [ngValue]="ship['_id']">{{ ship["shipName"] }}</option>
              }
            </select>
          </gray-label-wrapper>
        }
        <!-- -->
        @if (selectedInstruction().code === 181 || selectedInstruction().code === 191 || selectedInstruction().code === 401) {
          <gray-label-wrapper [fieldLabel]="'Fleet Name'" [fieldName]="'fleet_name'" class="w-full text-black flex items-center">
            <input class="mx-4 px-4 form-input ism-black" type="text" [(ngModel)]="fleetOrder.note" required />
          </gray-label-wrapper>
        }
        <!-- -->
        @if (selectedInstruction().code === 103 || selectedInstruction().code === 181 || selectedInstruction().code === 191) {
          <gray-label-wrapper [fieldLabel]="'Warp Point'" [fieldName]="'warp_point'" class="w-full text-black flex items-center">
            <select class="mx-4 px-6 form-select ism-black" [(ngModel)]="element">
              @for (infoWarpPoint of informationWarpPoints | orderBy: "strategicHex"; track $index) {
                <option [ngValue]="infoWarpPoint">{{ infoWarpPoint["strategicHex"] + infoWarpPoint["destString"] }}</option>
              }
            </select>
          </gray-label-wrapper>
        }
        <!-- -->
        @if (selectedInstruction().code === 104) {
          <gray-label-wrapper [fieldLabel]="'Star'" [fieldName]="'star'" class="w-full text-black flex items-center">
            <select class="mx-4 px-6 form-select ism-black" [(ngModel)]="element">
              @for (star of stars | orderBy: "locator"; track $index) {
                <option [ngValue]="star">{{ star["locator"] }}</option>
              }
            </select>
          </gray-label-wrapper>
        }
        <!-- -->
        @if (selectedInstruction().code === 131) {
          <gray-label-wrapper [fieldLabel]="'Hex Array'" [fieldName]="'hex_array'" class="w-full text-black flex items-center">
            <input class="mx-4 px-4 form-input ism-black" type="text" [(ngModel)]="fleetOrder.hex" required />
          </gray-label-wrapper>
        }
        <!-- -->
        @if (selectedInstruction().options) {
          <gray-label-wrapper [fieldLabel]="'Options'" [fieldName]="'options'" class="w-full text-black flex items-center">
            <select class="mx-4 px-6 form-select ism-black" [(ngModel)]="fleetOrder.option">
              @for (option of options[selectedInstruction().options]; track $index) {
                <option [ngValue]="option" [disabled]="!selectedInstruction().optionGood[selectedInstruction().code + '' + $index]">{{ option }}</option>
              }
            </select>
          </gray-label-wrapper>
        }
        <!-- -->
        @if (selectedInstruction().code === 511 && fleetOrder.option === "Duration") {
          <gray-label-wrapper [fieldLabel]="'Time to Loiter'" [fieldName]="'loiter'" class="w-full text-black flex items-center">
            <input class="mx-2 px-4 form-input ism-black" type="text" [(ngModel)]="fleetOrder.hex" required />
          </gray-label-wrapper>
        }
        <!-- -->
        @if (selectedInstruction() && !(selectedInstruction().code === 201 || selectedInstruction().code === 401 || selectedInstruction().code === 411 || selectedInstruction().code === 501 || selectedInstruction().code === 511)) {
          <gray-label-wrapper [fieldLabel]="'Speed'" [fieldName]="'speed'" class="w-full text-black flex items-center">
            <input class="mx-4 px-2 form-input ism-black" type="text" [(ngModel)]="fleetOrder.speed" min="1" required />
          </gray-label-wrapper>
        }
      </div>
    }
  </ng-container>
</edit-dialog>
