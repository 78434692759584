<div id="_SHIPCLASS-CENTER_" class="w-full flex flex-col text-1.25 iBTTiny:w-11/12 md:w-full iBTTiny:mx-auto md:m-0">
  <div class="ism-white text-1.75">Ship Class List</div>
  <div class="w-full flex flex-col sticky z-10">
    <ship-class-title-and-legend [legendDisplay]="'Shared Ship Classes'"></ship-class-title-and-legend>
  </div>

  @for(shipClass of shipClasses() | orderBy:'className'; track shipClass) {
  <div class="my-2 w-full text-white flex flex-col border-1">
    <div id="_SHIPCLASS-LINE-1_" class="mx-2 flex items-center justify-between">
      <div class="w-1/4 flex items-center">
        <div class="mr-2 lanPixel2xl:whitespace-nowrap tooltip-tr" data-text="Ship Class Name">
          {{shipClass.className}}
        </div>

        <div id="_ICON-SET_" class="mx-1 flex items-center">
          <a type="button" class="p-2" (click)="copyShipClass(shipClass)">
            <ism-svg [name]="'plus'"></ism-svg>
          </a>
        </div>
      </div>

      <div class="text-center flex items-center tooltip-tr lanPixel2xl:whitespace-nowrap" data-text="Hull Class">{{shipClass.hullClass}}</div>

      <div class="flex items-center justify-center">
        @if(shipClass.atmosphericCapability){
        <div class="px-2 text-center tooltip-tl" data-text="Atmospheric Capabilities">AC</div>
        }
        @if(shipClass.advancedManeuvering>0){
        <div class="px-2 text-center flex flex-1 tooltip-tl" data-text="Adv.Manuvering">AM({{shipClass.advancedManeuvering}})</div>
        }
        @if(shipClass.xo>0){
        <div class="px-2 text-center flex flex-1 tooltip-tl" data-text="External Ordinace">{{shipClass.xo}} XO</div>
        }
        @if(shipClass.xog>0){
        <div class="px-2 text-center flex flex-1 tooltip-tl" data-text="Ext-Ord / Gun Boats">{{shipClass.xog}} XOg</div>
        }
      </div>

      <div class="text-right flex tooltip-tl lanPixel2xl:whitespace-nowrap" data-text="Hull Space">{{shipClass.actualSize}} HS</div>
    </div>

    <div id="_SHIPCLASS-LINE-2_" class="mx-2 flex items-center justify-start">
      <div class="text-left tooltip-tr lanPixel2xl:whitespace-nowrap" data-text="Turn Mode">[{{shipClass.turnMode}}]</div>
      <div class="px-2 text-center break-all tooltip-tr lanPixel2xl:whitespace-normal" data-text="Ship System Design">{{shipClass.ssd}}</div>
      <div class="text-right tooltip-tl lanPixel2xl:whitespace-nowrap" data-text="SPEED: Max / Cruise">
        [{{shipClass.speedMaxStrategic || 0}} / {{shipClass.speedCruiseStrategic || 0}}]
      </div>
    </div>

    <div id="_SHIPCLASS-LINE-3_" class="mx-2 flex items-center justify-between">
      <div id="_OUTFIT_" class="text-left flex items-center lanPixel2xl:whitespace-nowrap">
        @if((shipClass.mgCount+shipClass.boatBayPoints+shipClass.xo)>0) {
        @if(shipClass.outfit.length>2) {
        <div class="tooltip-tr" data-text="OUTFIT: Amunitions, Mounts, & Small Craft">{{shipClass.outfit | outfitKeyValue}}
        </div>
        }
        @if(!shipClass.outfit || shipClass.outfit.length<=2) {
        <div class="flex-1">Incomplete Outfit</div>
        }
        }
      </div>

      @if(shipClass.vCount>0) {
      <div id="_FIGHTER-OUTFIT_" class="px-2 text-right flex items-center lanPixel2xl:whitespace-nowrap">
        @if(shipClass.fighterOutfit.length>2) {
        <div class="text-right tooltip-tr" data-text="Fighter Outfit">{{shipClass.fighterOutfit | outfitKeyValue}}</div>
        }
        @if(!shipClass.fighterOutfit || shipClass.outfit.length==2) {
        <div class="px-1 text-right">Incomplete Fighter Outfit</div>
        }
      </div>
      }
    </div>

    <div id="_SHIPCLASS-LINE-4_" class="mx-2 flex items-center justify-between">
      <div class="flex items-center">
        <div class="tooltip-tr" data-text="Build Costs">BC: {{shipClass.buildCost}} MC</div>
        <div class="px-2 text-center tooltip-tr" data-text="Maintenance Fund">Maint:
          {{shipClass.maintenanceCost}} MC
        </div>
      </div>
      <div class="flex items-center">
        @if(shipClass.mgCount>0) {
        <div class="text-center tooltip-tl lanPixel2xl:whitespace-nowrap" data-text="Missile Fund">M: {{shipClass.missileFund}} MC</div>
        }
        @if(shipClass.vCount>0) {
        <div class="pl-4 text-center tooltip-tl lanPixel2xl:whitespace-nowrap" data-text="Fighter Fund">F: {{shipClass.fighterFund}} MC</div>
        }
        @if((shipClass.boatBayPoints+shipClass.vCount)>0){
        <div class="pl-4 text-right tooltip-tl :whitespace-nowrap" data-text="Small Craft Cost">SC: {{shipClass.smallCraftCost}} MC</div>
        }
      </div>
    </div>
  </div>
  }
</div>
