[
  [
    {
      "fieldName": "className",
      "fieldLabel": "Class Name",
      "modelName": "className",
      "inputType": "text"
    }
  ],
  [
    {
      "fieldName": "hullClass",
      "fieldLabel": "Hull Class",
      "modelName": "hullClass",
      "inputType": "text"
    }
  ],
  [
    {
      "fieldName": "ssd",
      "fieldLabel": "SSD",
      "modelName": "ssd",
      "inputType": "textarea"
    }
  ],
  [
    {
      "fieldName": "turnMode",
      "fieldLabel": "Turn Mode",
      "modelName": "turnMode",
      "inputType": "text"
    }
  ],
  [
    {
      "fieldName": "freighterFlag",
      "fieldLabel": "Freighter Flag",
      "modelName": "freighterFlag",
      "inputType": "checkbox"
    }
  ],
  [
    {
      "fieldName": "actualSize",
      "fieldLabel": "Hull Spaces",
      "modelName": "actualSize",
      "inputType": "number"
    }
  ],
  [
    {
      "fieldName": "buildCost",
      "fieldLabel": "Cost",
      "modelName": "buildCost",
      "inputType": "number"
    }
  ],
  [
    {
      "fieldName": "inactive",
      "fieldLabel": "Inactive",
      "modelName": "inactive",
      "inputType": "checkbox"
    }
  ],
  [
    {
      "fieldName": "maintenanceCost",
      "fieldLabel": "Maintenance Cost",
      "modelName": "maintenanceCost",
      "inputType": "number"
    }
  ],
  [
    {
      "fieldName": "speedMaxStrategic",
      "fieldLabel": "Maximum Speed",
      "modelName": "speedMaxStrategic",
      "inputType": "text"
    }
  ],
  [
    {
      "fieldName": "atmosphericCapability",
      "fieldLabel": "Atmospheric Capability",
      "modelName": "atmosphericCapability",
      "inputType": "checkbox"
    }
  ],
  [
    {
      "fieldName": "advancedManeuvering",
      "fieldLabel": "Advanced Maneuvering",
      "modelName": "advancedManeuvering",
      "inputType": "number"
    }
  ],
  [
    {
      "fieldName": "xo",
      "fieldLabel": "XO Mount Points",
      "modelName": "xo",
      "inputType": "number"
    }
  ],
  [
    {
      "fieldName": "xog",
      "fieldLabel": "Gunboat Mount Points",
      "modelName": "xog",
      "inputType": "number"
    }
  ],
  [
    {
      "fieldName": "missileFund",
      "fieldLabel": "Missile Fund",
      "modelName": "missileFund",
      "inputType": "text"
    }
  ],
  [
    {
      "fieldName": "fighterFund",
      "modelName": "fighterFund",
      "fieldLabel": "Fighter Fund",
      "inputType": "text"
    }
  ],
  [
    {
      "fieldName": "smallCraftCost",
      "fieldLabel": "Small Craft Cost",
      "modelName": "smallCraftCost",
      "inputType": "number"
    }
  ],
  [
    {
      "fieldName": "share",
      "fieldLabel": "Share Design",
      "modelName": "share",
      "inputType": "checkbox"
    }
  ]
]
