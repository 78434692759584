import { Component, effect, EventEmitter, inject, input, model, Output, signal, TemplateRef, WritableSignal } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

import { CustomOptionService } from '../../services/custom-option.service';
import { SessionService } from '../../services/session.service';

import { authorizedMarshal } from '../../auth/auth.guard';

import { FieldDescription } from '../../interfaces/field-description';
import { CustomOption } from '../../interfaces/custom-option';

@Component({
  selector: 'edit-dialog-custom',
  templateUrl: './edit-dialog-custom.component.html'
})
export class EditDialogCustomComponent {
  session = inject(SessionService);
  customOptionService = inject(CustomOptionService);

  customize: WritableSignal<boolean> = signal(false);
  title = input.required<string>();
  document = model.required<any>();
  structureName = input<string>();
  structure = input.required<FieldDescription[][]>();
  templateXref = input<{ [key: string]: TemplateRef<any>; }>();
  templateCount = input.required<number>();
  @Output() action = new EventEmitter<string>();
  ready = signal<boolean>(false);
  derivedStructure: WritableSignal<FieldDescription[][]> = signal(undefined);
  structureId: string = undefined;

  authorizedMarshal: boolean = authorizedMarshal() || false;
  showEmptyRows: boolean = false;
  showStructure: boolean = false;
  showData: boolean = false;
  structureToRestore: string = undefined;

  constructor () {
    effect(() => {
      let count = this.templateCount();
      console.log('templateCount: ', count);
      if (count == null || (count > 0 && !this.templateXref())) {
        return;
      }
      console.log('templateXref: ', this.templateXref());
      let isItReady = count === 0 || (count > 0 && (this.templateXref() != null));
      console.log('isItReady: ', isItReady);
      this.ready.set(isItReady);
    }, { allowSignalWrites: true });

    effect(() => {
      if (!this.structureName() || !this.structure()) {
        return;
      }
      this.customOptionService.getCustomOptionForCampaignAndName$(this.session.campaign._id, this.structureName()).subscribe(
        option => {
          let structureClone = JSON.parse(JSON.stringify(this.structure()));
          if (option && Object.hasOwn(option, 'optionSettings')) {
            this.structureId = option._id;
            structureClone = JSON.parse(option.optionSettings);
          }
          this.derivedStructure.set(structureClone);
        }
      );
    }, { allowSignalWrites: true });
  }

  private createPayload () {
    let customOption: CustomOption = {
      campaignId: this.session.campaign._id,
      optionName: this.structureName(),
      optionSettings: JSON.stringify(this.derivedStructure())
    };
    return customOption;
  };

  saveStructure () {
    this.session.iAmBusy();
    console.log("SAVING CUSTOMIZED STRUCTURE");
    this.customOptionService.saveCustomOption$(this.createPayload()).subscribe(
      (option) => {
        this.structureId = option._id;
        this.session.setNotifyMessage('CustomOption saved!');
        this.session.iAmNotBusy();
      }
    );
  };

  cancelModification () {
    console.log("Cancel Structure Modification");
    this.derivedStructure.set(JSON.parse(this.structureToRestore));
  };

  resetModification () {
    this.session.iAmBusy();
    console.log("RESETTING TO DEFAULT STRUCTURE");
    this.customOptionService.deleteCustomOptionForId$(this.structureId).subscribe(
      () => {
        this.session.setNotifyMessage('Custom Row and Fields deleted!');
        this.derivedStructure.set(this.structure());
        this.session.iAmNotBusy();
      }
    );
  };

  // For FieldDragDrop
  rowDrop (event: CdkDragDrop<any>) {
    console.log('rowDropEvent: ', event, typeof event);
    if (event.previousContainer === event.container) {
      //   // Reorder items within the same list
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // Move items between lists
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    // console.log('structure: ', JSON.stringify(this.derivedStructure()));
  };

  fieldDrop (event: CdkDragDrop<any>) {
    console.log('fieldDropEvent: ', event, typeof event);
    if (event.previousContainer === event.container) {
      // Reorder items within the same list
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // Move items between lists
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    // console.log('ShipEdit_rowData: ', JSON.stringify(this.document()));
  };

  toggleCustomize () {
    this.customize.set(!this.customize());
    if (this.customize()) {
      this.structureToRestore = JSON.stringify(this.derivedStructure());
    }
  };

  submit () {
    this.action.emit('save');
  };

  cancel () {
    this.action.emit('cancel');
  };

}
