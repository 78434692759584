[
  [
    {
      "fieldName": "shipName",
      "fieldLabel": "Ship Name",
      "modelName": "shipName",
      "inputType": "text"
    }
  ],
  [
    {
      "fieldName": "hullClass",
      "fieldLabel": "Hull Class",
      "modelName": "hullClass",
      "inputType": "text"
    },
    {
      "fieldName": "actualSize",
      "fieldLabel": "Hull Spaces",
      "modelName": "actualSize",
      "inputType": "number"
    }
  ],
  [
    {
      "fieldName": "ssd",
      "fieldLabel": "SSD",
      "modelName": "ssd",
      "inputType": "textarea"
    }
  ],
  [
    {
      "fieldName": "damageOverlay",
      "fieldLabel": "Damage Overlay",
      "modelName": "damageOverlay",
      "inputType": "text"
    },
    {
      "fieldName": "damageOverlaySize",
      "fieldLabel": "Damage Overlay Size",
      "modelName": "damageOverlaySize",
      "inputType": "number"
    }
  ],
  [
    {
      "fieldName": "state",
      "fieldLabel": "State",
      "modelName": "state",
      "inputType": "text"
    },
    {
      "fieldName": "turnMode",
      "fieldLabel": "Turn Mode",
      "modelName": "turnMode",
      "inputType": "text"
    }
  ],
  [
    {
      "fieldName": "freighterFlag",
      "fieldLabel": "Freighter Flag",
      "modelName": "freighterFlag",
      "inputType": "checkbox"
    },
    {
      "fieldName": "battlesFought",
      "fieldLabel": "Battles Fought",
      "modelName": "battlesFought",
      "inputType": "number"
    }
  ],
  [
    {
      "fieldName": "atmosphericCapability",
      "fieldLabel": "Atmospheric Capability",
      "modelName": "atmosphericCapability",
      "inputType": "checkbox"
    },
    {
      "fieldName": "advancedManeuvering",
      "fieldLabel": "Advanced Maneuvering",
      "modelName": "advancedManeuvering",
      "inputType": "number"
    }
  ],
  [
    {
      "fieldName": "maintenanceOnBoard",
      "fieldLabel": "Maintenance On-Board",
      "modelName": "maintenanceOnBoard",
      "inputType": "number"
    },
    {
      "fieldName": "maintenanceCost",
      "fieldLabel": "Maintenance Cost",
      "modelName": "maintenanceCost",
      "inputType": "number"
    }
  ],
  [
    {
      "fieldName": "xo",
      "fieldLabel": "XO Mount Points",
      "modelName": "xo",
      "inputType": "number"
    },
    {
      "fieldName": "xog",
      "fieldLabel": "Gunboat Mount Points",
      "modelName": "xog",
      "inputType": "number"
    }
  ],
  [
    {
      "fieldName": "shipClassId",
      "fieldLabel": "Ship Class",
      "modelName": "shipClassId",
      "inputType": "text"
    },
    {
      "fieldName": "grade",
      "fieldLabel": "Grade",
      "modelName": "grade",
      "inputType": "text"
    }
  ],
  [
    {
      "fieldName": "shipYardPoints",
      "fieldLabel": "Ship Yard Points",
      "modelName": "shipYardPoints",
      "inputType": "number"
    },
    {
      "fieldName": "syCount",
      "fieldLabel": "SY Count",
      "modelName": "syCount",
      "inputType": "number"
    }
  ],
  [
    {
      "fieldName": "turnActivated",
      "fieldLabel": "Turn Activated",
      "modelName": "turnActivated",
      "inputType": "number"
    },
    {
      "fieldName": "turnDeactivated",
      "fieldLabel": "Turn Deactivated",
      "modelName": "turnDeactivated",
      "inputType": "number"
    }
  ],
  [
    {
      "fieldName": "speedCruiseStrategic",
      "fieldLabel": "Cruising Speed",
      "modelName": "speedCruiseStrategic",
      "inputType": "text"
    },
    {
      "fieldName": "speedMaxStrategic",
      "fieldLabel": "Maximum Speed",
      "modelName": "speedMaxStrategic",
      "inputType": "text"
    }
  ],
  [
    {
      "fieldName": "movementExpended",
      "fieldLabel": "Time in current direction",
      "modelName": "movementExpended",
      "inputType": "number"
    }
  ]
]
