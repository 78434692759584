import { Component, TemplateRef, effect, signal, viewChild } from '@angular/core';

import { DialogHandlerComponent } from '../../shared/dialog-handler/dialog-handler.component';

import { Fleet } from '../../interfaces/fleet';
import { StarSystemCrossReference } from '../../interfaces/information-star-system';

import structure from './fleet-edit-structure.json';

@Component({
  selector: 'fleet-edit',
  templateUrl: './fleet-edit.component.html',
})
export class FleetEditComponent extends DialogHandlerComponent {
  starSystemSelect = viewChild.required<TemplateRef<any>>('starSystemId');

  title: string = 'Fleet Edit';
  fleetToEdit: Fleet;

  starSystemCrossReference: StarSystemCrossReference[] = [];
  selectXRef = signal<{ [key: string]: TemplateRef<any>; }>(undefined);

  constructor () {
    super();

    this.structureName.set("FleetEditFields");
    this.importedRowFieldStructure.set(structure);

    this.fleetToEdit = Object.assign({}, this.data.document as Fleet);
    this.starSystemCrossReference = this.data['starSystemCrossReference'];

    effect(() => {
      if (!this.starSystemSelect()) {
        return;
      }
      this.selectXRef.set({
        starSystemId: this.starSystemSelect()
      });
    }, { allowSignalWrites: true });
  };

  override save () {
    this.dialogRef.close({
      documentName: "Fleet",
      document: this.fleetToEdit
    });
  };
};
