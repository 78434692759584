import { Component } from '@angular/core';

import { DialogHandlerComponent } from '../../shared/dialog-handler/dialog-handler.component';

import { Ship } from '../../interfaces/ship';

import structure from './ship-edit-structure.json';

@Component({
  selector: 'ship-edit',
  templateUrl: './ship-edit.component.html',
})
export class ShipEditComponent extends DialogHandlerComponent {
  title: string = 'Ship Edit';
  shipToEdit!: Ship;

  constructor () {
    super();

    this.importedRowFieldStructure.set(structure);
    this.structureName.set("ShipEditFields");

    this.shipToEdit = Object.assign({}, this.data.document as Ship);
  };

  override save () {
    this.dialogRef.close({
      documentName: "Ship",
      document: this.shipToEdit
    });
  };
};
