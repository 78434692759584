[
  [
    {
      "fieldName": "fleetName",
      "fieldLabel": "Fleet Name",
      "modelName": "fleetName",
      "inputType": "text"
    }
  ],
  [
    {
      "fieldName": "starSystemId",
      "fieldLabel": "Star System",
      "modelName": "starSystemId",
      "inputType": "select"
    }
  ],
  [
    {
      "fieldName": "locationHex",
      "fieldLabel": "Location",
      "modelName": "locationHex",
      "inputType": "text"
    }
  ],
  [
    {
      "fieldName": "instanceIdentifier",
      "fieldLabel": "Instance Identifier",
      "modelName": "instanceIdentifier",
      "inputType": "number"
    }
  ],
  [
    {
      "fieldName": "commandShipId",
      "fieldLabel": "Command ShipId",
      "modelName": "commandShipId",
      "inputType": "text"
    }
  ],
  [
    {
      "fieldName": "turnMode",
      "fieldLabel": "Turn Mode",
      "modelName": "turnMode",
      "inputType": "number"
    }
  ],
  [
    {
      "fieldName": "status",
      "fieldLabel": "Status",
      "modelName": "status",
      "inputType": "text"
    }
  ],
  [
    {
      "fieldName": "heading",
      "fieldLabel": "Heading",
      "modelName": "heading",
      "inputType": "number"
    }
  ],
  [
    {
      "fieldName": "speed",
      "fieldLabel": "Speed",
      "modelName": "speed",
      "inputType": "number"
    }
  ],
  [
    {
      "fieldName": "screenDistance",
      "fieldLabel": "Screen Distance",
      "modelName": "screenDistance",
      "inputType": "number"
    }
  ],
  [
    {
      "fieldName": "turnActivated",
      "fieldLabel": "Turn Activated",
      "modelName": "turnActivated",
      "inputType": "number"
    }
  ],
  [
    {
      "fieldName": "turnDeactivated",
      "fieldLabel": "Turn Deactivated",
      "modelName": "turnDeactivated",
      "inputType": "number"
    }
  ],
  [
    {
      "fieldName": "elapsed",
      "fieldLabel": "Elapsed",
      "modelName": "elapsed",
      "inputType": "number"
    }
  ],
  [
    {
      "fieldName": "exitWarpPoint",
      "fieldLabel": "Exit Warp Point",
      "modelName": "exitWarpPoint",
      "inputType": "text"
    }
  ],
  [
    {
      "fieldName": "mode",
      "fieldLabel": "Mode",
      "modelName": "mode",
      "inputType": "text"
    }
  ],
  [
    {
      "fieldName": "shipIds",
      "fieldLabel": "Ship Ids",
      "modelName": "shipIds",
      "inputType": "text"
    }
  ]
]
