import { Component, ElementRef, Injector, QueryList, Signal, ViewChildren, WritableSignal, computed, effect, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Dialog } from '@angular/cdk/dialog';
import { map, tap } from 'rxjs';

import { FleetEditComponent } from '../fleet-edit/fleet-edit.component';

import { authorizedMarshal } from '../../auth/auth.guard';

import { FleetOrderService } from '../../services/fleet-order.service';
import { FleetService } from '../../services/fleet.service';
import { InformationStarSystemService } from '../../services/information-star-system.service';
import { InformationWarpPointService } from '../../services/information-warp-point.service';
import { SessionService } from '../../services/session.service';
import { ShipService } from '../../services/ship.service';
import { StarSystemService } from '../../services/star-system.service';
import { TableService } from '../../services/table.service';

import { Campaign } from '../../interfaces/campaign';
import { DialogData } from '../../interfaces/dialog-data';
import { Fleet } from '../../interfaces/fleet';
import { FleetOrder } from '../../interfaces/fleet-order';
import { InformationStarSystem, StarSystemCrossReference } from '../../interfaces/information-star-system';
import { InformationWarpPoint } from '../../interfaces/information-warp-point';
import { Ship } from '../../interfaces/ship';
import { StarSystem } from '../../interfaces/star-system';
import { Race } from '../../interfaces/race';
import { FleetInstruction } from '../../interfaces/fleet-instruction';
import { CampaignWithRace } from '../../base/campaign-race';

@Component({
  selector: 'fleet-administration',
  templateUrl: './fleet-administration.component.html',
})
export class FleetAdministrationComponent extends CampaignWithRace {
  @ViewChildren('accordionItem', { read: ElementRef }) accordionElements!: QueryList<ElementRef>;
  injector = inject(Injector);
  private fleetOrderService = inject(FleetOrderService);
  private fleetService = inject(FleetService);
  private informationStarSystemService = inject(InformationStarSystemService);
  private informationWarpPointService = inject(InformationWarpPointService);
  private shipService = inject(ShipService);
  private starSystemService = inject(StarSystemService);
  private tableService = inject(TableService);
  private dialog = inject(Dialog);

  fleets: WritableSignal<Fleet[]> = signal([]);
  fleetById: { [key: string]: Fleet; } = {};
  starSystems: Signal<StarSystem[]>;
  starSystemsById: { [key: string]: StarSystem; } = {};
  fleetInstructions: Signal<FleetInstruction[]>;
  fleetOrdersByFleetId: { [key: string]: FleetOrder[]; } = {};
  ships: Signal<Ship[]>;
  fleetOrders: Signal<FleetOrder[]>;
  informationWarpPoints: Signal<InformationWarpPoint[]>;
  informationWarpPointsByKey: { [key: string]: (InformationWarpPoint); } = {};
  informationWarpPointsByStarSystemId: { [key: string]: InformationWarpPoint[]; } = {}; informationStarSystems: Signal<InformationStarSystem[]>;
  authorizedMarshal: boolean;
  starSystemCrossReference: StarSystemCrossReference[] = [];
  editFleetId: string;
  fleetInstructionByCode: Signal<{ [key: string]: any; }>;

  constructor () {
    super();
    this.authorizedMarshal = authorizedMarshal();

    this.fleetInstructions = toSignal<any[]>(this.tableService.getBaseTableType('instructions'));
    this.fleetInstructionByCode = computed(() => {
      return this.fleetInstructions().reduce((hash, instruction) =>
        (hash[instruction.code] = instruction, hash),
        {} as { [key: string]: any; }
      );
    });

    window.scrollTo(0, 0);
  };

  ngOnInit () {
    this.fleetService.getFleetsForRaceId$(this.race()._id).pipe(
      tap(fleets => fleets.reduce((hash, fleet) =>
        (this.fleetById[fleet._id] = fleet as Fleet, hash),
        this.fleetById
      )),
      map(fleets => this.fleets.set(fleets))
    ).subscribe();

    this.starSystems = toSignal(
      this.starSystemService.getAllStarSystemsForCampaign(this.campaign()._id),
      { injector: this.injector }
    );

    this.ships = toSignal(
      this.shipService.getShipsForRaceId$(this.race()._id),
      { injector: this.injector }
    );

    this.fleetOrders = toSignal(
      this.fleetOrderService.getFleetOrdersForRaceId(this.race()._id),
      { injector: this.injector }
    );

    this.informationStarSystems = toSignal(
      this.informationStarSystemService.getInformationStarSystemForRaceId(this.race()._id),
      { injector: this.injector }
    );

    this.informationWarpPoints = toSignal(
      this.informationWarpPointService.getLeanInformationWarpPointsForRaceId(this.race()._id),
      { injector: this.injector }
    );

    effect(() => {
      if (!this.starSystems() || !this.fleets() || !this.fleetOrders() || !this.informationWarpPoints() || !this.informationStarSystems() || !this.fleetInstructionByCode()) {
        return;
      }

      this.starSystemsById = this.starSystems().reduce(
        (hash, starSystem) => {
          hash[starSystem._id] = starSystem;
          return hash;
        }, this.starSystemsById
      );

      for (const fleetOrder of this.fleetOrders()) {
        let fleetId: string = fleetOrder.fleetId;
        let fleet: Fleet = this.fleetById[fleetId];
        let fleetInstruction: FleetInstruction = this.fleetInstructionByCode()[fleetOrder.code];
        let targetFleet: Fleet = this.fleetById[fleetOrder.element];
        let informationWarpPoint: InformationWarpPoint = this.informationWarpPointsByKey[fleetOrder.element];
        this.fleetOrderService.buildDisplay(fleetOrder, fleet, fleetInstruction, targetFleet, informationWarpPoint);
        if (!this.fleetOrdersByFleetId[fleetId]) {
          this.fleetOrdersByFleetId[fleetId] = [];
        }
        this.fleetOrdersByFleetId[fleetId].push(fleetOrder);
      }

      this.informationWarpPointsByKey = this.informationWarpPoints().reduce(
        (hash, informationWarpPoint) => {
          informationWarpPoint.strategicHex = informationWarpPoint.warpPoint.strategicHex;
          if (informationWarpPoint.turnExplored > 0) {
            informationWarpPoint['destinationSystemNumber'] = informationWarpPoint.warpPoint.destination;
            informationWarpPoint['destinationHex'] = informationWarpPoint.warpPoint.destinationStrategicHex;
            informationWarpPoint['destinationStarSystemId'] = informationWarpPoint.warpPoint.destinationStarSystem;
          }
          delete informationWarpPoint.warpPoint;

          hash[informationWarpPoint.starSystemId + informationWarpPoint.strategicHex] = informationWarpPoint;
          hash[informationWarpPoint._id] = informationWarpPoint;

          let iwpsForSsid = (this.informationWarpPointsByStarSystemId[informationWarpPoint.starSystemId] || []) as InformationWarpPoint[];
          iwpsForSsid.push(informationWarpPoint);
          this.informationWarpPointsByStarSystemId[informationWarpPoint.starSystemId] = iwpsForSsid;

          return hash;
        },
        this.informationWarpPointsByKey
      );

      // build starSystemCrossReference
      if (this.authorizedMarshal) {
        for (const starSystem of this.starSystems()) {
          this.starSystemCrossReference.push({
            starSystemId: starSystem._id,
            starSystemNumber: starSystem.number
          });
        }
      }
      else {
        for (const informationStarSystem of this.informationStarSystems()) {
          this.starSystemCrossReference.push({
            starSystemId: informationStarSystem.starSystemId,
            starSystemNumber: informationStarSystem.starSystemNumber,
            akaName: informationStarSystem.akaName,
          });
        }
      }
    }, { injector: this.injector });
  };

  opened (fleetIndex: number) {
    let openedAccordion = this.accordionElements.get(fleetIndex) as ElementRef;
    setTimeout(() => {
      let newScrollTop = openedAccordion.nativeElement.offsetTop;
      window.scrollTo({
        behavior: 'smooth',
        top: newScrollTop,
      });
    });
  };

  processFleet (fleet: Fleet) {
    this.fleetService.saveFleet$(fleet).pipe(
      tap(savedFleet => {
        let fleetFound = false;
        this.fleets.update(f => f.map(
          nextFleet => {
            if (nextFleet._id === savedFleet._id) {
              fleetFound = true;
              nextFleet = savedFleet;
            }
            return nextFleet;
          }
        ));
        if (!fleetFound) {
          this.fleets.update(f => ([...f, savedFleet]));
        }
        this.session.setNotifyMessage('Fleet Saved');
        this.session.iAmNotBusy();
      })
    ).subscribe();
  };

  editFleet (event: Event, fleet: Fleet) {
    if (event) {
      event.stopPropagation();
    }
    this.editFleetId = fleet._id ? fleet._id : "0";
    const dialogRef = this.dialog.open<DialogData>(FleetEditComponent, {
      width: '65%',
      height: '75%',
      panelClass: 'edit-dialog',
      data: {
        documentName: "Fleet",
        document: fleet,
        starSystemsById: this.starSystemsById,
        starSystemCrossReference: this.starSystemCrossReference
      },
    });

    dialogRef.closed.subscribe(result => {
      if (result) {
        this.session.iAmBusy();
        this.processFleet(result.document as Fleet);
      }
    });
  };

  createFleet () {
    let fleet = this.fleetService.getNewFleet(this.campaign()._id, this.race()._id);
    this.editFleet(null, fleet);
  };
};
